import {
  Box,
  Flex,
  Icon,
  mdBumps,
  Notification,
  NotificationProps,
  PrimaryButton,
  smBumps,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { CustomerAccount, isAccountActive } from '../../../domain'
import { useSettleStripe } from '../../../hooks/stripe'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { useCurrentPath } from '../../../infrastructure/routing'

const tKey = TKey('components.outstanding-payment-notification')

export interface OutstandingPaymentNotificationProps extends NotificationProps {
  customerAccount: CustomerAccount
  onSettled?: () => void
}

export const OutstandingPaymentNotification: FunctionComponent<
  OutstandingPaymentNotificationProps
> = ({ customerAccount, onSettled, ...props }) => {
  const { t } = useWriskTranslation()
  const path = useCurrentPath()

  const onStripePayment = useSettleStripe({
    accountId: customerAccount.accountId,
    successPath: path,
    cancelPath: path,
    onSuccess: onSettled,
  })

  const translation = isAccountActive(customerAccount) ? 'active' : 'inactive'

  return customerAccount.outstandingAmount > 0 ? (
    <Notification
      type='standalone'
      variant='critical'
      flexDirection={['column', 'column', 'row']}
      {...props}
    >
      <Flex
        justifyContent='flex-start'
        mr={[0, 0, 4]}
        mb={[4, 4, 0]}
        width={[1, 1, 3 / 5]}
      >
        <Icon
          icon='warning'
          size='small'
          mr={smBumps}
          display={['none', 'none', 'block']}
        />
        <Typo>
          <Trans
            t={t}
            i18nKey={tKey(translation)}
            values={{
              outstandingAmount: t(tFormats('currency.long'), {
                amount: customerAccount.outstandingAmount / 100,
              }),
            }}
          />
        </Typo>
      </Flex>
      <Box width={[1, 1, 2 / 5]} pl={[0, 0, ...mdBumps.slice(2)]}>
        <PrimaryButton
          onClick={() => onStripePayment.execute()}
          loading={onStripePayment.loading}
          variant='critical'
          width={1}
        >
          <Typo whiteSpace='nowrap'>{t(tKey('actions.pay-now'))} </Typo>
        </PrimaryButton>
      </Box>
    </Notification>
  ) : null
}
