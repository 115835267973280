import {
  Box,
  BoxProps,
  Flex,
  HelpLinkIFrame,
  LinkAnchor,
  mdBumps,
  smBumps,
  Typo,
  useModal,
  xxlBumps,
} from '@wrisk/ui-components'
import React, { useCallback } from 'react'

import {
  AccrualType,
  getPremiumByAccrualType,
  PriceOption,
  Quote,
  RiskProfileStatus,
} from '../../../../domain'
import {
  TKey,
  TKeyBuilder,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import {
  getOptionalCoverLink,
  getOptionalCoversContentBaseUrl,
  useConfig,
} from '../../../../state/configuration'
import { useProduct } from '../../../product/productContext'
import { AddOnButtons } from './components/Buttons'
import { AddOnHeader } from './components/Header'

interface AddOnSelectorProps extends BoxProps {
  quote: Quote
  cover: PriceOption
  onChange: (cover: PriceOption) => void
  tKey: TKeyBuilder
}

const tCovers = TKey('covers')

export const AddOnBoolean: React.FC<AddOnSelectorProps> = ({
  quote,
  cover,
  onChange,
  tKey,
  ...props
}) => {
  const { t } = useWriskTranslation()
  const modal = useModal()

  const { scheme } = useProduct()

  const contentBaseUrl = useConfig(getOptionalCoversContentBaseUrl(scheme.schemeCode))
  const link = getOptionalCoverLink(contentBaseUrl, quote.wordingVersion, cover)

  const id = `section-add-on-${cover.coverCode}`

  const onChangeCallback = useCallback(
    (active: boolean | undefined) => onChange({ ...cover, active }),
    [onChange, cover],
  )

  const onLinkMoreClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      modal.show({
        content: <HelpLinkIFrame src={link} />,
        variant: 'iframe',
      })
    },
    [modal, link],
  )

  return cover.ratingStatus === RiskProfileStatus.QUOTE ? (
    <Box data-testid={cover.coverCode} mb={xxlBumps} width={1} {...props}>
      <Flex flexDirection='column' justifyContent='flex-start' mb={2} variant='raised'>
        <AddOnHeader
          tKey={tKey}
          amount={getPremiumByAccrualType([cover], AccrualType.MONTHLY)}
          disabled={false}
          text={t<string>(tKey('add-ons', cover.coverCode, 'header'))}
        />
        <Box px={mdBumps} pb={mdBumps} width={1}>
          <Typo mb={smBumps}>{t(tCovers(cover.coverCode, 'description'))}</Typo>
          <LinkAnchor typoSize='sm' onClick={onLinkMoreClick} variant='standalone'>
            {t(tKey('add-ons', cover.coverCode, 'actions.more'))}
          </LinkAnchor>
        </Box>
      </Flex>
      <AddOnButtons
        selected={cover.active}
        prefixForIds={id}
        titleYes={t(tKey('add-ons', cover.coverCode, 'actions.yes'))}
        titleNo={t(tKey('add-ons', cover.coverCode, 'actions.no'))}
        onSelect={onChangeCallback}
      />
    </Box>
  ) : (
    <Box mb={xxlBumps} width={1} {...props}>
      <Flex flexDirection='column' justifyContent='flex-start' mb={2} variant='raised'>
        <AddOnHeader
          tKey={tKey}
          disabled={true}
          text={t<string>(tCovers(cover.coverCode, 'name'))}
        />
        <Box px={mdBumps} pb={mdBumps} width={1}>
          <Typo>{t(tCovers(cover.coverCode, 'unavailable'))}</Typo>
        </Box>
      </Flex>
    </Box>
  )
}
