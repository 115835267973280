import { FilterInput, Flex, PrimaryButton, xsBumps } from '@wrisk/ui-components'
import { range } from 'lodash'
import { DateTime } from 'luxon'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { isVehicleOfMake, Specification } from '../../../../domain'
import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { VehicleMakesFilter } from './VehicleMakesFilter'
import { VehicleModelsFilter } from './VehicleModelsFilter'
import { VehicleResultsFilter } from './VehicleResultsFilter'

const renderYear = (year: number) => year.toString()

const matchYear = (text: string) => (yr: number) => yr.toString().includes(text)

export type VehicleSelectValue = Partial<{
  specification: Specification
  vrn: string
}>

export interface VehicleSelectConfig {
  makes?: string[]
  earliestYear?: number
  vrnRequired?: boolean
}

export interface VehicleSelectProps {
  config?: VehicleSelectConfig
  value?: VehicleSelectValue
  onChange: (specification: VehicleSelectValue | undefined) => void
}

const tKey = TKey('components.vehicle-select')

const isSpecification = (spec: Partial<Specification>): spec is Specification =>
  Boolean(spec.vehicleCode)

export const VehicleSelect: FunctionComponent<VehicleSelectProps> = ({
  config,
  value,
  onChange,
}) => {
  const { t } = useWriskTranslation()

  const defaultMake = config?.makes?.length === 1 ? config?.makes[0] : undefined

  const [specification, setSpecification] = useState<Partial<Specification>>(
    isVehicleOfMake(config?.makes)(value?.specification) ?? { make: defaultMake },
  )

  const years = useMemo(
    () => range(DateTime.local().get('year'), (config?.earliestYear ?? 1940) - 1),
    [config?.earliestYear],
  )

  const onInternalConfirm = useCallback(() => {
    if (isSpecification(specification)) {
      onChange({
        ...value,
        specification,
      })
    }
  }, [specification, onChange, value])

  const onResultSelect = useCallback((newSpecification?: Specification) => {
    setSpecification((prevState) => newSpecification ?? prevState)
  }, [])

  const setSpecificationAndResetSelection = useCallback(
    (newSpecification: Partial<Specification>) => {
      setSpecification(newSpecification)
      onChange(undefined)
    },
    [onChange],
  )

  const setYear = useCallback(
    (year: number | undefined) => {
      setSpecificationAndResetSelection({
        year,
        make: defaultMake,
      })
    },
    [defaultMake, setSpecificationAndResetSelection],
  )

  const setMake = useCallback(
    (make: string | undefined) => {
      setSpecificationAndResetSelection({
        year: specification.year,
        make,
      })
    },
    [specification, setSpecificationAndResetSelection],
  )

  const setModel = useCallback(
    (model: string | undefined) => {
      setSpecificationAndResetSelection({
        year: specification.year,
        make: specification.make,
        model,
      })
    },
    [specification, setSpecificationAndResetSelection],
  )

  const makesDropdown = specification.year && !defaultMake && (
    <VehicleMakesFilter
      onSelect={setMake}
      year={specification.year}
      make={specification.make}
      allowedMakes={config?.makes}
    />
  )

  const modelsDropdown = specification.year && specification.make && (
    <VehicleModelsFilter
      onSelect={setModel}
      year={specification.year}
      make={specification.make}
      model={specification.model}
    />
  )

  const vehiclesResults = specification.year &&
    specification.make &&
    specification.model && (
      <VehicleResultsFilter
        onSelect={onResultSelect}
        year={specification.year}
        make={specification.make}
        model={specification.model}
        initialValue={
          Object.keys(specification).length === 3
            ? undefined
            : (specification as Specification)
        }
      />
    )

  const disabled = !isSpecification(specification)

  return (
    <Flex
      justifyContent='flex-start'
      flexDirection='column'
      alignItems='flex-start'
      flexGrow={1}
      width={1}
      rowGap={xsBumps}
    >
      <FilterInput
        data-testid='vehicle-year'
        width={1}
        placeholder='Year of manufacture'
        value={specification.year}
        onSelect={setYear}
        options={years}
        renderOption={renderYear}
        renderOptionText={renderYear}
        matchOption={matchYear}
      />

      {makesDropdown}
      {modelsDropdown}
      {vehiclesResults}

      <PrimaryButton
        data-testid='vehicle-confirm'
        disabled={disabled}
        onClick={onInternalConfirm}
      >
        {t(tKey('actions.confirm'))}
      </PrimaryButton>
    </Flex>
  )
}
