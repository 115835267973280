import React, { FunctionComponent, useMemo } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { isRenewalProposal } from '../../../domain'
import { useCurrentPath } from '../../../infrastructure/routing'
import { AppPath } from '../../AppPath'
import { ProposalAddOnsPage } from '../proposal/covers'
import { useProposal } from '../proposal/proposalContext'
import { ProposalPath } from '../proposal/ProposalPath'
import { RenewalDisclosureRouter, RenewalOptOutPage, RenewalSummaryPage } from './index'

export const RenewalRouter: FunctionComponent = () => {
  const { proposal } = useProposal()
  const currentPath = useCurrentPath()

  const homePage = useMemo(() => ({ url: AppPath.HOME, id: 'home' }), [])
  const summaryPage = useMemo(() => ({ url: currentPath, id: 'renewal' }), [currentPath])

  if (
    !isRenewalProposal(proposal) ||
    proposal.sourcePolicy.detail.renewalStopped ||
    !proposal.sourcePolicy.detail.autoRenewalEnabled
  ) {
    return <Navigate to={AppPath.HOME} />
  }

  return (
    <Routes>
      <Route key='quote' index element={<RenewalSummaryPage home={homePage} />} />
      <Route
        key='opt-out'
        path={ProposalPath.OPT_OUT}
        element={<RenewalOptOutPage parent={summaryPage} />}
      />

      <Route
        key='add-ons'
        path={ProposalPath.ADD_ONS}
        element={
          <ProposalAddOnsPage destination={summaryPage.url} parent={summaryPage} />
        }
      />

      <Route
        key='management'
        path={ProposalPath.DISCLOSURE + AppPath.WILDCARD}
        element={<RenewalDisclosureRouter parent={summaryPage} />}
      />

      <Route path='*' element={<Navigate to={AppPath.HOME} />} />
    </Routes>
  )
}
