import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
} from 'react'

export interface RefreshContextObj {
  refresh: () => void
}

const RefreshContext = createContext<RefreshContextObj | undefined>(undefined)

interface RefreshProviderProps extends PropsWithChildren {
  refresh: () => void
}

export const RefreshProvider: FunctionComponent<RefreshProviderProps> = ({
  children,
  refresh,
}) => {
  return <RefreshContext.Provider value={{ refresh }}>{children}</RefreshContext.Provider>
}

export const useRefresh = (): RefreshContextObj => {
  const context = useContext(RefreshContext)

  if (!context) {
    throw new Error('Cannot use RefreshContext when undefined.')
  }

  return context
}
