import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import { animated, useSpring } from 'react-spring'

import { Flex, PageContainer } from '../atoms'
import { pageEntranceSpringValues } from '../extensions'

export interface AnimatedPageProps extends PropsWithChildren {
  pageId?: string
  footer?: ReactNode
}

export const AnimatedPage: FunctionComponent<AnimatedPageProps> = ({
  children,
  footer,
  pageId,
  ...props
}) => {
  const styles = useSpring(pageEntranceSpringValues)

  return (
    <animated.div id={pageId} style={styles} {...props}>
      <Flex width={1} height='100%' flexDirection='column' alignItems='stretch'>
        <PageContainer>{children}</PageContainer>
        {footer}
      </Flex>
    </animated.div>
  )
}
