import { FlexProps } from '@wrisk/styled-system'
import { Flex, PrimaryButton, TertiaryButton } from '@wrisk/ui-components'
import { chain, isNil, keys, pickBy } from 'lodash'
import React, { FunctionComponent, useCallback, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

import { Address, generateLabel } from '../../../domain'
import {
  TKeyBuilder,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { AddressManualEntryFields } from './AddressManualEntryFields'

export interface AddressManualEntryProps extends FlexProps {
  name: string
  value?: Address
  onChange: (address: Address) => void
  onCancel?: () => void
  tKey: TKeyBuilder
  tName: string
  errorMessageClass?: string
}

export type AddressFieldsConfig = {
  [field in keyof Address]: {
    required?: boolean
    readonly?: boolean
    hidden?: boolean
  }
}

// Ordered for `label` generation
const ADDRESS_FIELDS_CONFIG: AddressFieldsConfig = {
  company: {
    required: false,
    readonly: true,
  },
  department: {
    required: false,
    readonly: true,
  },
  line1: {
    required: true,
    readonly: false,
  },
  line2: {
    required: false,
    readonly: false,
  },
  line3: {
    required: false,
    readonly: false,
  },
  line4: {
    required: false,
    readonly: true,
  },
  line5: {
    required: false,
    readonly: true,
  },
  city: {
    required: false,
    readonly: true,
  },
  postalCode: {
    required: false,
    readonly: true,
  },
  countryName: {
    required: false,
    readonly: true,
  },
  countryIso2: {
    hidden: true,
  },
  countryIso3: {
    hidden: true,
  },
  label: {
    hidden: true,
  },
}

export const AddressManualEntry: FunctionComponent<AddressManualEntryProps> = ({
  name,
  value,
  onChange,
  onCancel,
  tKey,
  tName,
  errorMessageClass,
  ...props
}) => {
  const { t } = useWriskTranslation()
  const { getValues, trigger, resetField } = useFormContext()

  const rollbackStateRef = useRef<Record<string, string> | null>(null)
  if (!rollbackStateRef.current) {
    rollbackStateRef.current = { ...getValues()[name] }
  }

  const generateAddress = useCallback(() => {
    const inputValues = getValues()[name]
    return chain({ ...value, ...inputValues })
      .pick(keys(ADDRESS_FIELDS_CONFIG))
      .thru((address) => ({
        ...address,
        label: generateLabel(
          keys(pickBy(ADDRESS_FIELDS_CONFIG, (config) => !config.hidden)),
          inputValues,
        ),
      }))
      .value() as Address
  }, [getValues, name, value])

  const revertChanges = useCallback(() => {
    const rollbackState = rollbackStateRef.current
    if (isNil(rollbackState)) return

    for (const [field, defaultValue] of Object.entries(rollbackState)) {
      resetField(`${name}.${field}`, { defaultValue })
    }
  }, [name, resetField])

  const handleCancel = useCallback(() => {
    revertChanges()
    onCancel?.()
  }, [onCancel, revertChanges])

  const handleConfirm = useCallback(async () => {
    if (await trigger([name])) {
      onChange(generateAddress())
    }
  }, [generateAddress, name, onChange, trigger])

  return (
    <Flex width={1} gap={8} flexDirection='column' alignItems='flex-start' {...props}>
      <AddressManualEntryFields
        config={ADDRESS_FIELDS_CONFIG}
        name={name}
        tKey={tKey}
        tName={tName}
        errorMessageClass={errorMessageClass}
      />
      <Flex gap={4}>
        <PrimaryButton onClick={handleConfirm}>
          {t(tKey('actions.confirm'))}
        </PrimaryButton>
        <TertiaryButton onClick={handleCancel}>
          {t(tKey('actions.cancel'))}
        </TertiaryButton>
      </Flex>
    </Flex>
  )
}
