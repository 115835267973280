import { loadStripe } from '@stripe/stripe-js'
import { useModal, WriskTheme } from '@wrisk/ui-components'
import React from 'react'
import { useTheme } from 'styled-components'

import {
  UnauthorizedModalContent,
  useAdminUnauthorised,
  usePrincipal,
} from '../application/authentication'
import { AdminSettleModalContent } from '../application/organisms/admin'
import { usePolicy } from '../application/product/policy/policyContext'
import { getPolicyholder, getPolicyVersion } from '../domain'
import { TKey, useWriskTranslation } from '../infrastructure/internationalisation'
import { getFeatureToggles, useConfig } from '../state/configuration'
import { pathWithOrigin } from '../util/dom'
import { useApiErrorHandlingAsyncCallback } from './auth'

const tKey = TKey('components.pay-now')

const {
  stripe: { apiKey, stripeAccount },
} = CONFIG

interface StripePayment {
  successPath: string
  cancelPath: string
}

interface StripeSettle extends StripePayment {
  accountId: string
  onSuccess?: () => void
}

interface StripeSetup extends StripePayment {
  clientReference?: string
}

export const useSettleStripe = ({
  accountId,
  successPath,
  cancelPath,
  onSuccess,
}: StripeSettle) => {
  const { adminSettle } = useConfig(getFeatureToggles)
  const { t } = useWriskTranslation()
  const theme = useTheme() as WriskTheme
  const { apiClient, isAdmin } = usePrincipal()
  const { policy } = usePolicy()
  const { quote } = getPolicyVersion(policy)
  const {
    data: { dateOfBirth },
  } = getPolicyholder(quote)
  const { show } = useModal()

  return useApiErrorHandlingAsyncCallback(async () => {
    if (isAdmin) {
      show({
        content: adminSettle ? ( // TODO temporary feature toggle
          <AdminSettleModalContent
            accountId={accountId}
            dateOfBirth={dateOfBirth}
            onSuccess={onSuccess}
          />
        ) : (
          <UnauthorizedModalContent />
        ),
      })
    } else {
      const stripe = await loadStripe(apiKey, { stripeAccount })

      await stripe?.redirectToCheckout(
        await apiClient.stripeSettleSession({
          accountId,
          cancelUrl: pathWithOrigin(cancelPath),
          successUrl: pathWithOrigin(successPath),
          lineItem: {
            description: t<string>(tKey('line-item.outstanding-description')),
            name: t(tKey('line-item.name')),
            imageUrl: theme.illus.lineItem
              ? pathWithOrigin(theme.illus.lineItem)
              : undefined,
          },
        }),
      )
    }
  })
}

export const useSetupStripe = ({
  successPath,
  cancelPath,
  clientReference,
}: StripeSetup) => {
  const { apiClient } = usePrincipal()

  const adminUnauthorised = useAdminUnauthorised()

  return useApiErrorHandlingAsyncCallback(
    adminUnauthorised(async () => {
      const stripe = await loadStripe(apiKey, { stripeAccount })

      await stripe?.redirectToCheckout(
        await apiClient.stripeSetupSession({
          clientReference,
          cancelUrl: pathWithOrigin(cancelPath),
          successUrl: pathWithOrigin(successPath),
        }),
      )
    }),
  )
}

export const useCheckoutStripe = ({ successPath, cancelPath }: StripePayment) => {
  const { t, i18n } = useWriskTranslation()
  const theme = useTheme() as WriskTheme

  const { apiClient } = usePrincipal()

  const adminUnauthorised = useAdminUnauthorised()

  return useApiErrorHandlingAsyncCallback(
    adminUnauthorised(async (paymentId: string) => {
      const stripe = await loadStripe(apiKey, { stripeAccount })

      await stripe?.redirectToCheckout(
        await apiClient.stripeCheckoutSession({
          paymentId,
          cancelUrl: pathWithOrigin(cancelPath),
          successUrl: pathWithOrigin(successPath),
          lineItem: {
            description: t<string>(tKey('line-item.description')),
            name: t(tKey('line-item.name')),
            imageUrl: theme.illus.lineItem
              ? pathWithOrigin(theme.illus.lineItem)
              : undefined,
          },
          locale: i18n.language,
        }),
      )
    }),
  )
}
