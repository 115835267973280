import {
  AnimatedPage,
  Banner,
  BannerSubtitle,
  BannerTitle,
  lgBumps,
  MainSection,
  NotificationContainer,
  SideSection,
  StickySidebar,
  WideContainer,
  xxlBumps,
  xxsBumps,
} from '@wrisk/ui-components'
import React from 'react'

import { getCurrentQuote, getPolicyholder } from '../../../../domain'
import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import {
  HowItWorksSection,
  ProductFaqSection,
  ProposalDocumentsSection,
} from '../../../organisms/product'
import { ProposalPromotionBanner } from '../../../organisms/promotion'
import {
  ProposalContinueButton,
  ProposalCoverSection,
  ProposalDetailsView,
  ProposalExcessSection,
  ProposalSidebar,
} from '../../../organisms/proposal'
import { Footer } from '../../../templates'
import {
  ProposalConfirmationBanner,
  useProposalConfirmation,
} from '../proposalConfirmation'
import { useProposal } from '../proposalContext'

const tKey = TKey('pages.proposal-configuration')

export const ProposalConfigurationPage = () => {
  const { t } = useWriskTranslation()

  const { proposal } = useProposal()
  const { isNotConfirmed } = useProposalConfirmation()

  const quote = getCurrentQuote(proposal)
  const profile = getPolicyholder(quote)

  return (
    <AnimatedPage
      pageId='proposal-configuration-page'
      footer={<Footer />}
      data-policynumber={proposal.policyNumber}
    >
      <Banner>
        <WideContainer flexDirection='column'>
          <BannerSubtitle mb={xxsBumps}>{t(tKey('subheader'))}</BannerSubtitle>
          <BannerTitle>{t(tKey('header'), { profile })}</BannerTitle>
        </WideContainer>
      </Banner>

      <WideContainer>
        <NotificationContainer mb={xxlBumps}>
          <ProposalConfirmationBanner />
          <ProposalPromotionBanner />
        </NotificationContainer>
      </WideContainer>

      <WideContainer variant='sidebar'>
        <StickySidebar>
          <SideSection>
            <ProposalSidebar key='desktop' disabled={isNotConfirmed} />
          </SideSection>
        </StickySidebar>
        <MainSection>
          <ProposalSidebar
            key='mobile'
            displayOn='mobile'
            mb={lgBumps}
            disabled={isNotConfirmed}
          />
          <ProposalDetailsView mb={xxlBumps} />
          <HowItWorksSection mb={xxlBumps} />
          <ProposalCoverSection mb={xxlBumps} />
          <ProposalExcessSection mb={xxlBumps} />
          <ProductFaqSection mb={xxlBumps} />
          <ProposalDocumentsSection quote={quote} mb={xxlBumps} />
          <ProposalContinueButton tKey={tKey} layout='fixed' disabled={isNotConfirmed} />
        </MainSection>
      </WideContainer>

      <WideContainer mt={xxlBumps}>
        <NotificationContainer mb={xxlBumps}>
          <ProposalConfirmationBanner />
        </NotificationContainer>
      </WideContainer>
    </AnimatedPage>
  )
}
