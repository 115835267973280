import {
  Flex,
  LinkAnchor,
  mdBumps,
  Section,
  SectionContent,
  SectionProps,
  SectionTitle,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { generatePath, Link } from 'react-router-dom'

import { toPolicyData } from '../../../../../../hooks/adjustments/policy/disclosure/data'
import {
  TKeyBuilder,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { QuoteManagementConfig } from '../../../../../../state/configuration'
import { getFormatter } from '../../../../../formBuilder'
import { PolicyAdjustmentPath } from '../../../adjustments'
import { usePolicy } from '../../../policyContext'

export interface AdjustmentSectionProps extends SectionProps {
  config: QuoteManagementConfig
  tKey: TKeyBuilder
}

export const PolicyAdjustmentSection: FunctionComponent<AdjustmentSectionProps> = ({
  config,
  tKey,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const { policy } = usePolicy()

  const toData = toPolicyData(policy.currentPolicyVersion)

  const [firstInput] = config.inputs
  const Formatter = getFormatter(firstInput.type)

  return (
    <Section data-testid={config.id} width={1} {...props}>
      <SectionTitle>{t(tKey('sections', config.id, 'header'))}</SectionTitle>
      <SectionContent>
        <Flex
          variant='raised'
          width={1}
          flexDirection='column'
          justifyContent='flex-start'
        >
          <Flex p={mdBumps} width={1}>
            <Typo fontWeight='bold' mr={2}>
              <Formatter input={firstInput} value={toData(firstInput.adjustment)} t={t} />
            </Typo>
            <LinkAnchor
              to={generatePath(PolicyAdjustmentPath.Section, { sectionId: config.id })}
              as={Link}
              variant='standalone'
              typoSize='sm'
              data-testid='update-link'
            >
              {t(tKey('actions.update'))}
            </LinkAnchor>
          </Flex>
        </Flex>
      </SectionContent>
    </Section>
  )
}
