import { Box, BoxProps, Typo } from '@wrisk/ui-components'
import { TFunction } from 'i18next'
import React, { FunctionComponent } from 'react'
import { get, useFormContext } from 'react-hook-form'

import { TKeyBuilder } from '../../../infrastructure/internationalisation'

interface ErrorMessageProps extends BoxProps {
  name: string
  className?: string

  tName: string
  tKey: TKeyBuilder
  t: TFunction
}

export const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({
  name,
  tKey,
  tName,
  t,
  className,
  ...props
}) => {
  const {
    formState: { errors },
  } = useFormContext()

  const error = get(errors, name)

  return error?.type ? (
    <Box {...props} className={className}>
      <Typo color='textCritical'>
        {error.message ||
          t([tKey(tName, 'validation', error.type), `defaults.validation.${error.type}`])}
      </Typo>
    </Box>
  ) : null
}
