import { logger } from '../../infrastructure/logging'
import { isAxiosError } from '../../util/axios'

export enum ApiError {
  NETWORK = 'NETWORK',
  UNEXPECTED = 'UNEXPECTED',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export interface ApiException {
  type: ApiError
  status?: number
  data: any // eslint-disable-line @typescript-eslint/no-explicit-any
  message?: string
}

// Error narrowing

export const isApiException = (error: unknown): error is ApiException =>
  Boolean(error) &&
  (error as ApiException).type !== undefined &&
  Object.values(ApiError).includes((error as ApiException).type)

export const isUnexpectedApiException = (error: unknown): error is ApiException =>
  isApiException(error) && error.type == ApiError.UNEXPECTED

export const isUnauthorizedApiException = (error: unknown): error is ApiException =>
  isApiException(error) && error.type == ApiError.UNAUTHORIZED

export const isRequestAbortedException = (error: Error): boolean =>
  Boolean(error) && error.message === 'Request aborted'

export const isNotFoundApiException = (error: unknown): error is ApiException =>
  isUnexpectedApiException(error) && error.status === 404

// Error client handling

export const withGenericErrorHandling = async <R>(func: () => Promise<R>): Promise<R> => {
  try {
    return await func()
  } catch (e) {
    if (!isAxiosError(e)) throw e
    if (e.response) {
      const type = e.response.status === 401 ? ApiError.UNAUTHORIZED : ApiError.UNEXPECTED
      throw { type, status: e.response.status, data: e.response.data } as ApiException
    } else {
      throw { type: ApiError.NETWORK, message: e.message } as ApiException
    }
  }
}

export const withSilentErrorHandling = async <R>(
  func: () => Promise<R>,
): Promise<R | void> => {
  try {
    return await func()
  } catch (e) {
    logger.error(e.message)
  }
}

// Error component handling

export const with404ErrorHandling = async <R>(
  func: () => Promise<R>,
): Promise<R | undefined> => {
  try {
    return await func()
  } catch (e) {
    if (isNotFoundApiException(e)) {
      return undefined
    }
    throw e
  }
}

export const withApiErrorHandling = async <R>(
  func: () => Promise<R>,
  onFailure: (error: ApiException) => void,
  statuses: number[] = [],
): Promise<R | undefined> => {
  try {
    return await func()
  } catch (e) {
    if (isUnexpectedApiException(e) && statuses.includes(e.status ?? 0)) {
      onFailure(e)
    } else {
      throw e
    }
  }
}

export const with410ErrorHandling = async <R>(
  func: () => Promise<R>,
  onFailure: (error: ApiException) => void,
): Promise<R | undefined> => withApiErrorHandling(func, onFailure, [410])
