import { FilterInput, InputMessage } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { ApiClient } from '../../../../clients/api'
import { useApiErrorHandlingAsync } from '../../../../hooks/auth'
import { usePrincipal } from '../../../authentication'

interface Props {
  onSelect: (make: string | undefined) => void
  year: number
  make?: string
  allowedMakes?: string[]
}

const dataLookup = (client: ApiClient, year: number) => client.lookupVehicleMakes(year)

const renderOption = (make: string) => make

const matchOption = (text: string) => (option: string) =>
  option.toLowerCase().includes(text.toLowerCase())

export const VehicleMakesFilter: FunctionComponent<Props> = ({
  onSelect,
  year,
  make,
  allowedMakes,
}) => {
  const { apiClient } = usePrincipal()

  const { result } = useApiErrorHandlingAsync(dataLookup, [apiClient, year])

  const makeOptions = result
    ? allowedMakes
      ? allowedMakes.filter((it) => result.includes(it))
      : result
    : undefined

  return makeOptions?.length === 0 ? (
    <InputMessage
      width={1}
    >{`We couldn't find any models manufactured in ${year}.`}</InputMessage>
  ) : (
    <FilterInput
      data-testid='vehicle-make'
      placeholder='Make'
      value={make}
      width={1}
      onSelect={onSelect}
      options={makeOptions ?? []}
      renderOption={renderOption}
      renderOptionText={renderOption}
      matchOption={matchOption}
    />
  )
}
