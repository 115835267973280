import {
  Box,
  lgBumps,
  MainSection,
  NotificationContainer,
  pageEntranceSpringValues,
  Section,
  SectionContent,
  SectionTitle,
  SideSection,
  WideContainer,
  xlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { animated, useSpring } from 'react-spring'

import {
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { getProduct, useConfig } from '../../../../../../state/configuration'
import { usePrincipal } from '../../../../../authentication'
import {
  CardMissingNotification,
  DirectDebitMissingNotification,
  NextStatementSummary,
  OutstandingPaymentNotification,
  StatementSummary,
} from '../../../../../organisms/billing'
import {
  BillingManager,
  useCustomerAccount,
  useNextStatement,
  usePaymentMethods,
  useStatementSummaries,
} from '../../../billing'
import { usePolicy } from '../../../policyContext'
import { BillingSidebar } from '../components/BillingSidebar'

const tKey = TKey('components.billing-tab')

const Content: FunctionComponent = () => {
  const { t } = useWriskTranslation()
  const { apiClient } = usePrincipal()
  const {
    policy: { policyNumber, schemeCode },
  } = usePolicy()

  const { hideBillingSidebar } = useConfig(getProduct(schemeCode))

  const { customerAccount, setCustomerAccount } = useCustomerAccount()

  const { statementSummaries, setStatementSummaries } = useStatementSummaries()
  const { nextStatement } = useNextStatement()

  const { paymentMethods } = usePaymentMethods()

  const spring = useSpring(pageEntranceSpringValues)

  const [latestStatementSummary, ...previousStatementSummaries] = statementSummaries

  const handleSettled = useCallback(async () => {
    const [[account], summaries] = await Promise.all([
      apiClient.getCustomerAccounts({ policyNumber }),
      apiClient.getStatementSummaries(customerAccount.accountId),
    ])

    setCustomerAccount(account)
    setStatementSummaries(summaries)
  }, [
    apiClient,
    customerAccount.accountId,
    policyNumber,
    setCustomerAccount,
    setStatementSummaries,
  ])

  return (
    <animated.div style={spring}>
      <WideContainer>
        <NotificationContainer mb={xlBumps}>
          <DirectDebitMissingNotification
            customerAccounts={[customerAccount]}
            paymentMethods={paymentMethods}
          />
          <CardMissingNotification
            customerAccounts={[customerAccount]}
            paymentMethods={paymentMethods}
          />
          <OutstandingPaymentNotification
            customerAccount={customerAccount}
            onSettled={handleSettled}
          />
        </NotificationContainer>
      </WideContainer>
      <WideContainer variant='sidebar'>
        <SideSection>
          {!hideBillingSidebar && <BillingSidebar mb={lgBumps} />}
        </SideSection>
        <MainSection>
          {Boolean(latestStatementSummary) && (
            <Section width={1} mb={lgBumps}>
              <SectionTitle>{t(tKey('latest-statement', 'header'))}</SectionTitle>
              <SectionContent>
                <Box variant='raised' p={2} width={1}>
                  <StatementSummary
                    statementSummary={latestStatementSummary}
                    accountId={customerAccount.accountId}
                  />
                </Box>
              </SectionContent>
            </Section>
          )}

          {nextStatement && (
            <Section width={1} mb={lgBumps}>
              <SectionTitle>{t(tKey('next-statement-estimate', 'header'))}</SectionTitle>
              <SectionContent>
                <Box variant='raised' p={2} width={1}>
                  <NextStatementSummary statement={nextStatement} />
                </Box>
              </SectionContent>
            </Section>
          )}

          {Boolean(previousStatementSummaries.length) && (
            <Section width={1}>
              <SectionTitle>{t(tKey('previous-statements', 'header'))}</SectionTitle>
              <SectionContent>
                <Box variant='raised' p={2} width={1}>
                  {previousStatementSummaries.map((it) => (
                    <StatementSummary
                      key={it.statementNumber}
                      statementSummary={it}
                      accountId={customerAccount.accountId}
                    />
                  ))}
                </Box>
              </SectionContent>
            </Section>
          )}
        </MainSection>
      </WideContainer>
    </animated.div>
  )
}

export const BillingTab: FunctionComponent = () => (
  <BillingManager>
    <Content />
  </BillingManager>
)
