import {
  Flex,
  Icon,
  Notification,
  NotificationProps,
  PrimaryButton,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  CustomerAccount,
  hasMissingPaymentMethod,
  PaymentMethod,
  PaymentMethodType,
} from '../../../domain'
import { useSetupMandate } from '../../../hooks/directDebit'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'

export interface DirectDebitMissingNotificationProps extends NotificationProps {
  paymentMethods: PaymentMethod[]
  customerAccounts: CustomerAccount[]
}

const tKey = TKey('components.direct-debit-missing-notification')

const Content: FunctionComponent<NotificationProps> = (props) => {
  const { t } = useWriskTranslation()

  const onSetupMandate = useSetupMandate()

  return (
    <Notification
      type='standalone'
      variant='critical'
      flexDirection={['column', 'column', 'row']}
      {...props}
    >
      <Flex mr={[0, 0, 4]} mb={[4, 4, 0]}>
        <Icon icon='warning' display={['none', 'none', 'block']} mr={4} />
        <Typo>{t(tKey('header'))}</Typo>
      </Flex>
      <PrimaryButton
        variant='critical'
        onClick={onSetupMandate.execute}
        loading={onSetupMandate.loading}
        layout={['default', 'default', 'fixed']}
        width={1}
        whiteSpace='nowrap'
      >
        {t(tKey('actions.set-up'))}
      </PrimaryButton>
    </Notification>
  )
}

export const DirectDebitMissingNotification: FunctionComponent<
  DirectDebitMissingNotificationProps
> = ({ paymentMethods, customerAccounts, ...props }) => {
  const paymentMethod = hasMissingPaymentMethod(
    customerAccounts,
    paymentMethods,
    PaymentMethodType.BACS_DEBIT,
  )
  return paymentMethod ? <Content {...props} /> : null
}
