import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import { Flex, xsBumps } from '../atoms'
import { FilterInput } from './FilterInput'
import { InputMessage } from './InputMessage'

export interface CascadingFilterInputOption {
  id: string
  text: string
}

export interface CascadingFilterInputConfigItem<TItem> {
  id?: string
  placeholder?: string
  mapItemsToOptions: (items: TItem[]) => CascadingFilterInputOption[]
  matchItemToOption: (option: CascadingFilterInputOption) => (item: TItem) => boolean
  matchOptionToItem: (item: TItem) => (option: CascadingFilterInputOption) => boolean
}

interface CascadingFilterInputProps<TItem> {
  value?: TItem
  items: TItem[]
  onSelect: (item: TItem | undefined) => void
  config: CascadingFilterInputConfigItem<TItem>[]
  errorMessage?: string
}

const renderOption = (option: CascadingFilterInputOption) => option.text

const matchOption = (text: string) => (option: CascadingFilterInputOption) =>
  option.text.toLowerCase().includes(text.toLowerCase())

export function CascadingFilterInput<TObject>(
  props: CascadingFilterInputProps<TObject>,
): ReactElement {
  const { items, onSelect, config, value, errorMessage } = props

  const [
    { id, mapItemsToOptions, matchItemToOption, matchOptionToItem, placeholder },
    ...nextConfig
  ] = config

  const options = useMemo(() => mapItemsToOptions(items), [mapItemsToOptions, items])

  const initialOption =
    (value !== undefined && options.find(matchOptionToItem(value))) || undefined

  const [option, setOption] = useState<CascadingFilterInputOption | undefined>(
    initialOption,
  )

  const filteredItems = useMemo(
    () => (option === undefined ? [] : items.filter(matchItemToOption(option))),
    [items, matchItemToOption, option],
  )

  useEffect(() => {
    if (filteredItems.length === 1) {
      onSelect(filteredItems[0])
    }
  }, [onSelect, filteredItems])

  useEffect(() => {
    if (options.length === 1) {
      setOption(options[0])
    }
  }, [options])

  const onDropdownSelect = useCallback(
    (v: CascadingFilterInputOption | undefined) => {
      setOption(v)
      onSelect(undefined)
    },
    [onSelect],
  )

  const dropdown =
    options.length > 1 ? (
      <FilterInput
        data-testid={id}
        width={1}
        value={option}
        placeholder={placeholder}
        onSelect={onDropdownSelect}
        options={options}
        renderOption={renderOption}
        renderOptionText={renderOption}
        matchOption={matchOption}
      />
    ) : null

  const nextItems = options.length > 1 ? filteredItems : items

  const nextDropdown =
    nextConfig.length > 0 && nextItems.length > 1 ? (
      <CascadingFilterInput
        value={value}
        items={nextItems}
        onSelect={onSelect}
        config={nextConfig}
        errorMessage={errorMessage}
      />
    ) : nextConfig.length === 0 && errorMessage ? (
      <InputMessage>{errorMessage}</InputMessage>
    ) : null

  return (
    <Flex width={1} flexDirection='column' justifyContent='flex-start' rowGap={xsBumps}>
      {dropdown}
      {nextDropdown}
    </Flex>
  )
}
