import {
  AdditionalProfileDisclosureChange,
  Data,
  getCurrentQuote,
  getInsuredProfile,
  PolicyChangeType,
  Profile,
  Proposal,
  ProposalAdjustmentRequest,
} from '../../../../domain'
import {
  AdjustmentInputConfig,
  ChangeType,
  ProfileChangeConfig,
} from '../../../../state/configuration'
import { hasUpdates, toUpdates } from '../../helpers'

const toAdditionalProfileDisclosureReducer =
  (existingData: Data, data: Data, { profileCode }: Profile) =>
  (
    proposalAdjustment: ProposalAdjustmentRequest,
    input: AdjustmentInputConfig<ProfileChangeConfig>,
  ): ProposalAdjustmentRequest => {
    const updates = toUpdates(existingData, data)

    const changes: AdditionalProfileDisclosureChange[] = updates(input).map((update) => ({
      changeType: PolicyChangeType.ADDITIONAL_PROFILE_DISCLOSURE_CHANGE,
      profileCode,
      update,
    }))

    return {
      ...proposalAdjustment,
      changes: [...proposalAdjustment.changes, ...changes],
    }
  }

export const toAdditionalProfileProposalAdjustment =
  (
    inputs: AdjustmentInputConfig[],
    applyRating: boolean,
    proposal: Proposal,
    profile: Profile,
  ) =>
  (existingData: Data, data: Data): ProposalAdjustmentRequest => {
    const quote = getCurrentQuote(proposal)

    const profileDisclosureReducer = toAdditionalProfileDisclosureReducer(
      existingData,
      data,
      profile,
    )

    return inputs.filter(hasUpdates(existingData, data)).reduce(
      (proposalAdjustment: ProposalAdjustmentRequest, input) => {
        switch (input.adjustment.changeType) {
          case ChangeType.PROFILE_DISCLOSURE:
            return profileDisclosureReducer(
              proposalAdjustment,
              input as AdjustmentInputConfig<ProfileChangeConfig>,
            )
          default:
            throw new Error(
              `AdjustmentChangeType ${input.adjustment.changeType} not supported by this`,
            )
        }
      },
      {
        applyRating,
        changes: !getInsuredProfile(quote, profile.profileCode)
          ? [
              {
                changeType: PolicyChangeType.ADDITIONAL_PROFILE_CREATE_CHANGE,
                profileCode: profile.profileCode,
              },
            ]
          : [],
      },
    )
  }
