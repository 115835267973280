import React, {
  ChangeEvent,
  KeyboardEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useState,
} from 'react'

import { Box, BoxProps, Icon, IconButton, InputWrapper, TextInput } from '../atoms'
import { useOutsideClickRef } from '../extensions'
import { InputMessage } from './InputMessage'
import { SelectResult } from './SelectResult'
import { SelectResults } from './SelectResults'

export interface FilterInputProps<T> extends BoxProps {
  placeholder?: string
  value?: T
  onSelect: (option: T | undefined) => void
  options: T[]
  renderOption: (option: T) => ReactNode
  renderOptionText: (option: T) => string
  matchOption: (text: string) => (option: T) => boolean
}

export const FilterInput = <T,>({
  placeholder,
  value,
  onSelect,
  options,
  renderOption,
  renderOptionText,
  matchOption,
  ...props
}: FilterInputProps<T>): ReactElement => {
  const [open, setOpen] = useState(false)
  const [inputText, setInputText] = useState<string>(
    (value && renderOptionText(value)) || '',
  )
  const filteredOptions = options.filter(matchOption(inputText))

  const hideOptions = useCallback(() => setOpen(false), [])
  const showOptions = useCallback(() => {
    setOpen(true)
    setInputText('')
    onSelect(undefined)
  }, [onSelect])

  const selectOption = useCallback<(option: T) => void>(
    (option) => {
      hideOptions()
      setInputText(renderOptionText(option))
      onSelect(option)
    },
    [hideOptions, renderOptionText, onSelect],
  )

  const onInputChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => setInputText(target.value),
    [],
  )
  const onInputKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter' && filteredOptions.length === 1) {
        selectOption(filteredOptions[0])
      }
    },
    [filteredOptions, selectOption],
  )

  const onIconClick = useCallback(() => {
    if (open) {
      hideOptions()
    } else {
      showOptions()
    }
  }, [open, hideOptions, showOptions])

  const selectResults = filteredOptions.length ? (
    <SelectResults mt={2}>
      {filteredOptions.map((result, index) => (
        <SelectResult key={index} onClickParams={result} onClick={selectOption}>
          {renderOption(result)}
        </SelectResult>
      ))}
    </SelectResults>
  ) : (
    <InputMessage>Sorry, no matches found</InputMessage>
  )

  filteredOptions.map((result, index) => (
    <SelectResult key={index} onClickParams={result} onClick={selectOption}>
      {renderOption(result)}
    </SelectResult>
  ))

  const ref = useOutsideClickRef<HTMLDivElement>(hideOptions)

  return (
    <Box ref={ref} {...props}>
      <InputWrapper width={1}>
        <TextInput
          width={1}
          variant='ghost'
          value={inputText}
          onChange={onInputChange}
          onFocus={showOptions}
          onKeyDown={onInputKeyPress}
          placeholder={placeholder}
          autoComplete='off'
          disabled={options.length === 0}
        />
        <IconButton onClick={onIconClick} mr={-2}>
          <Icon
            icon={
              options.length === 0 ? 'loadingDark' : open ? 'chevronUp' : 'chevronDown'
            }
            transition='default'
            size='tiny'
          />
        </IconButton>
      </InputWrapper>
      {open && selectResults}
    </Box>
  )
}
