import {
  ExpandableSection,
  ExpandableSectionProps,
  Flex,
  HelpFlexAnchor,
  Icon,
  smBumps,
  Typo,
  xsBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  AccrualType,
  getPremiumByAccrualType,
  isCoverActive,
  Quote,
  RiskProfileStatus,
} from '../../../domain'
import { useOptionalCovers } from '../../../hooks/covers'
import {
  tCovers,
  tFormats,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import {
  getCoreAssetType,
  getOptionalCoverLink,
  getOptionalCoversContentBaseUrl,
  useConfig,
} from '../../../state/configuration'
import { useProduct } from '../../product/productContext'

export interface OptionalCoversExpandableProps extends ExpandableSectionProps {
  quote: Quote
  showPremiums?: boolean
}

export const OptionalCoversExpandable: FunctionComponent<
  OptionalCoversExpandableProps
> = ({ quote, showPremiums, header, ...props }) => {
  const { t } = useWriskTranslation()

  const { scheme, product } = useProduct()

  const optionalCovers = useOptionalCovers(quote, product).filter(isCoverActive)
  const contentBaseUrl = useConfig(getOptionalCoversContentBaseUrl(scheme.schemeCode))

  const assetType = getCoreAssetType(product)

  const cells = optionalCovers.map((it) => {
    const inReferral = it.ratingStatus === RiskProfileStatus.REFERRAL
    const inQuote = it.ratingStatus === RiskProfileStatus.QUOTE

    const iconName = inReferral ? 'cross' : it.active ? 'tick' : 'plus'
    const color = inReferral ? 'inactive' : undefined

    const link = getOptionalCoverLink(contentBaseUrl, quote.wordingVersion, it)

    const premium = showPremiums && inQuote && (
      <Typo color={color} typoSize='sm'>
        {t(tFormats('currency.long'), {
          amount: getPremiumByAccrualType([it], AccrualType.MONTHLY),
        })}
      </Typo>
    )
    return (
      <HelpFlexAnchor
        key={`${assetType.code}.${it.coverCode}`}
        href={link}
        width={1}
        variant='highlight'
        px={smBumps}
        py={3}
      >
        <Flex>
          <Icon icon={iconName} color={color} size='small' mr={smBumps} />
          <Typo typoSize='sm' color={color}>
            {t(tCovers(it.coverCode, 'name'))}
          </Typo>
        </Flex>
        <Flex>
          {premium}
          <Icon color={color} size='tiny' icon='info' ml={xsBumps} />
        </Flex>
      </HelpFlexAnchor>
    )
  })

  return cells.length ? (
    <ExpandableSection header={header} {...props}>
      {cells}
    </ExpandableSection>
  ) : null
}
