import {
  Box,
  Heading,
  mdBumps,
  Section,
  SectionContent,
  SectionDescription,
  SectionProps,
  SectionTitle,
  smBumps,
  xsBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { isAuxiliary, PriceOption, Quote } from '../../../domain'
import { useIncludedCovers, useOptionalCovers } from '../../../hooks/covers'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { getCoreAssetType } from '../../../state/configuration'
import { useProduct } from '../../product/productContext'
import { CoverEndorsements } from './CoverEndorsements'
import { IncludedCoverItems } from './IncludedCoverItems'
import { IncludedCoverItemsWithInfo } from './IncludedCoverItemsWithInfo'
import { OptionalFlexAnchor } from './OptionalFlexAnchor'

export interface CoverSectionProps extends SectionProps {
  quote: Quote
  onChange?: (cover: PriceOption) => void
}

const tKey = TKey('components.cover.comprehensive-core')

export const CoverSection: FunctionComponent<CoverSectionProps> = ({
  quote,
  onChange,
  ...props
}) => {
  const { t } = useWriskTranslation()
  const { product } = useProduct()
  const { covers } = getCoreAssetType(product)

  const includedCovers = useIncludedCovers(product)
  const optionalCovers = useOptionalCovers(quote, product).filter(isAuxiliary(product))

  const includedCoverItems = covers.withInfo ? (
    <IncludedCoverItemsWithInfo includedCovers={includedCovers} />
  ) : (
    <IncludedCoverItems includedCovers={includedCovers} />
  )

  const subheader = t(tKey('subheader'), { defaultValue: null })

  return (
    <Section width={1} {...props}>
      <SectionTitle>{t(tKey('header'))}</SectionTitle>
      {subheader && <SectionDescription>{subheader}</SectionDescription>}
      <SectionContent>
        <Box width={1} variant='raised'>
          {includedCoverItems}
          {optionalCovers.length > 0 && (
            <Box width={1} p={2} borderTopWidth={1}>
              <Heading mt={2} px={smBumps} pb={xsBumps} variant='h3'>
                {t(tKey('optional.header'))}
              </Heading>
              <Box width={1}>
                {optionalCovers.map((cover) => (
                  <OptionalFlexAnchor
                    key={cover.coverCode}
                    cover={cover}
                    quote={quote}
                    onChange={onChange}
                  />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </SectionContent>
      <CoverEndorsements quote={quote} mt={mdBumps} />
    </Section>
  )
}
