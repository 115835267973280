import React, { FunctionComponent, ReactNode } from 'react'

import { Box, Flex, mdBumps, TertiaryButton, Typo } from '../../atoms'

export interface OverridableConfirmationViewProps {
  label: ReactNode | null
  onChange: () => void
  changeAction: string
  isOverridable?: boolean
  onOverride?: () => void
  overrideAction?: string
}

export const OverridableConfirmationView: FunctionComponent<
  OverridableConfirmationViewProps
> = ({ label, onChange, changeAction, isOverridable, onOverride, overrideAction }) => {
  return (
    <Flex width={1} variant='raised' p={mdBumps} flexWrap='wrap' gap={mdBumps}>
      <Box>
        <Typo fontFamily='input'>{label}</Typo>
      </Box>
      <Flex gap={2}>
        {isOverridable && overrideAction && onOverride && (
          <TertiaryButton
            layout='small'
            data-testid='override-button'
            onClick={onOverride}
          >
            {overrideAction}
          </TertiaryButton>
        )}
        <TertiaryButton layout='small' data-testid='change-button' onClick={onChange}>
          {changeAction}
        </TertiaryButton>
      </Flex>
    </Flex>
  )
}
