import { AxiosInstance, AxiosResponse } from 'axios'

import { Address, FindAddressItem, SearchDataEntry, Specification } from '../../../domain'
import { withGenericErrorHandling } from '../errors'

export interface LookupClient {
  lookupVehicle: (licencePlate: string) => Promise<Specification | null>
  lookupVehicles: (year: number, make: string, model: string) => Promise<Specification[]>
  lookupVehicleMakes: (year: number) => Promise<string[]>
  lookupVehicleModels: (year: number, make: string) => Promise<string[]>
  searchData: (tableCode: string, searchTerm: string) => Promise<SearchDataEntry[]>
  getDataEntry: (tableCode: string, key: string) => Promise<SearchDataEntry>
  getAddress: (id: string) => Promise<Address>
  findAddresses: (
    text: string,
    options?: Partial<{ container: string; countries: string[] }>,
  ) => Promise<FindAddressItem[]>
}

export const createLookupClient = (
  getInstance: () => Promise<AxiosInstance>,
): LookupClient => ({
  lookupVehicle: (vrn) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response: AxiosResponse<Specification[]> = await instance.get(
        '/lookup/vehicles/vehicles/vrn',
        {
          params: { vrn: vrn },
        },
      )
      const [vehicle] = response.data.map((it) => ({ ...it, vrn: vrn }))
      return vehicle ?? null
    }),

  lookupVehicles: (year, make, model) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response: AxiosResponse<Specification[]> = await instance.get(
        `/lookup/vehicles/vehicles`,
        {
          params: {
            year,
            make,
            model,
          },
        },
      )
      return response.data
    }),

  lookupVehicleMakes: (year) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response: AxiosResponse<string[]> = await instance.get(
        `/lookup/vehicles/makes`,
        { params: { year } },
      )
      return response.data
    }),

  lookupVehicleModels: (year, make) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response: AxiosResponse<string[]> = await instance.get(
        `/lookup/vehicles/models`,
        {
          params: {
            year,
            make,
          },
        },
      )
      return response.data
    }),

  searchData: (tableCode, searchTerm) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response: AxiosResponse<SearchDataEntry[]> = await instance.get(
        `/lookup/data/${tableCode}`,
        {
          params: { searchTerm },
        },
      )
      return response.data
    }),

  getDataEntry: (tableCode, key) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const encodedKey = encodeURIComponent(key)
      const response: AxiosResponse<SearchDataEntry> = await instance.get(
        `/lookup/data/${tableCode}/${encodedKey}`,
      )
      return response.data
    }),

  getAddress: (id) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response: AxiosResponse<Address> = await instance.get(`/lookup/address/${id}`)
      return response.data
    }),

  findAddresses: (text, options = {}) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response: AxiosResponse<FindAddressItem[]> = await instance.get(
        `/lookup/addresses`,
        {
          params: { text, ...options },
          paramsSerializer: {
            indexes: null,
          },
        },
      )
      return response.data
    }),
})
