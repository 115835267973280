import React, { FunctionComponent, useCallback } from 'react'

import { Specification } from '../../../../domain'
import { VehicleCard, VehicleLookup, VehicleLookupConfig } from '../../../organisms/form'
import { FormInputProps } from '../../types'
import { VehicleInputValue } from './types'

export const VehicleLookupFormInput: FunctionComponent<
  FormInputProps<VehicleInputValue, VehicleLookupConfig>
> = ({ t, tKey, tName, value, onChange, meta }) => {
  const onInternalChange = useCallback(() => {
    onChange(null)
  }, [onChange])

  const onSearch = useCallback(
    (specification?: Specification) => {
      onChange(
        specification
          ? {
              regLookup: true,
              specification,
              vrn: specification?.vrn ?? undefined,
            }
          : null,
      )
    },
    [onChange],
  )

  return value?.specification ? (
    <VehicleCard
      specification={value?.specification}
      vrn={value?.vrn}
      onChange={onInternalChange}
    />
  ) : (
    <VehicleLookup
      onSearch={onSearch}
      value={value?.vrn}
      config={meta}
      error={t(tKey(tName, 'validation', 'notFoundLookup'))}
    />
  )
}
