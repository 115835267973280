import {
  Flex,
  FlexAnchor,
  Icon,
  mdBumps,
  smBumps,
  Status,
  Typo,
} from '@wrisk/ui-components'
import { isNil } from 'lodash'
import React, { FunctionComponent, useCallback, useState } from 'react'

import {
  getCurrentPolicyVersion,
  PolicyAdjustmentResponse,
} from '../../../../../../../domain'
import { toPolicyData } from '../../../../../../../hooks/adjustments/policy/disclosure/data'
import {
  tDefaults,
  TKey,
  useWriskTranslation,
} from '../../../../../../../infrastructure/internationalisation'
import { QuoteManagementConfig } from '../../../../../../../state/configuration'
import { getFormatter } from '../../../../../../formBuilder'
import { EffectiveAt } from './EffectiveAt'

export interface PolicyChangesProps {
  result: PolicyAdjustmentResponse
  config: QuoteManagementConfig
}

const tKey = TKey('components.policy-changes')

export const PolicyChangesFlex: FunctionComponent<PolicyChangesProps> = ({
  config,
  result,
}) => {
  const { t } = useWriskTranslation()

  const [expanded, setExpanded] = useState(false)
  const onExpand = useCallback(() => setExpanded((e) => !e), [setExpanded])

  const policyVersion = getCurrentPolicyVersion(result.updatedPolicyVersions)

  const toData = toPolicyData(policyVersion)

  const [firstInput, ...otherInputs] = config.inputs.filter(
    ({ adjustment }) => !isNil(toData(adjustment)),
  )

  const Formatter = getFormatter(firstInput.type)

  const expandedComponents = otherInputs.map((input) => {
    const Formatter = getFormatter(input.type)

    return (
      <Flex
        width={1}
        key={input.name}
        px={mdBumps}
        py={2}
        flexDirection='column'
        alignItems='flex-start'
      >
        <Typo typoSize='xs' color='bodySecondary' mb={1}>
          {t(tDefaults('product.headers', input.name))}
        </Typo>
        <Typo as='pre'>
          <Formatter input={input} value={toData(input.adjustment)} t={t} />
        </Typo>
      </Flex>
    )
  })

  return (
    <Flex alignItems='stretch' flexDirection='column' pt={2} variant='raised' width={1}>
      <FlexAnchor mx={2} p={smBumps} variant='highlight' onClick={onExpand}>
        <Typo fontWeight='bold' mr={2}>
          <Formatter input={firstInput} value={toData(firstInput.adjustment)} t={t} />
        </Typo>
        <Flex>
          <Status typoSize='xs'>{t(tKey('status', 'changed'))}</Status>
          <Icon
            icon='chevronDown'
            size='tiny'
            rotate={expanded ? 180 : 0}
            ml={2}
            transition='default'
          />
        </Flex>
      </FlexAnchor>
      {expanded && expandedComponents}
      <EffectiveAt mt={2} effectiveAt={result.updatedPolicyVersions[0].startedAt} />
    </Flex>
  )
}
