import { Box, SectionContent, SectionTitle, Typo, xxlBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { Quote } from '../../../domain'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { ExcessExpandable } from '../excess'
import { ExclusionsExpandable } from './ExclusionsExpandable'
import { IncludedCoversExpandable } from './IncludedCoversExpandable'
import { OptionalCoversExpandable } from './OptionalCoversExpandable'

const tKey = TKey('components.your-cover')

export interface YourCoverSectionProps {
  quote: Quote
}

export const YourCoverSection: FunctionComponent<YourCoverSectionProps> = ({ quote }) => {
  const { t } = useWriskTranslation()

  return (
    <Box width={1} mb={xxlBumps}>
      <SectionTitle>{t(tKey('header'))}</SectionTitle>
      <SectionContent>
        <Box width={1} p={2} variant='raised'>
          <IncludedCoversExpandable
            reversed
            quote={quote}
            header={<Typo fontWeight='bold'>{t(tKey('headings.core-covers'))}</Typo>}
          />
          <OptionalCoversExpandable
            reversed
            quote={quote}
            showPremiums={true}
            header={<Typo fontWeight='bold'>{t(tKey('headings.optional-covers'))}</Typo>}
          />
          <ExcessExpandable
            reversed
            quote={quote}
            header={<Typo fontWeight='bold'>{t(tKey('headings.excesses'))}</Typo>}
          />
          <ExclusionsExpandable
            reversed
            header={<Typo fontWeight='bold'>{t(tKey('headings.exclusions'))}</Typo>}
          />
        </Box>
      </SectionContent>
    </Box>
  )
}
