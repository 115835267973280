import {
  BooleanInput,
  Box,
  Flex,
  Heading,
  mdBumps,
  PrimaryButton,
  smBumps,
  TertiaryButton,
  Typo,
  xsBumps,
} from '@wrisk/ui-components'
import { TFunction } from 'i18next'
import { isNil } from 'lodash'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useAsyncCallback } from 'react-async-hook'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import { TExtends, TKeyBuilder } from '../../../infrastructure/internationalisation'
import { InputConfig } from '../../../state/configuration'
import { useConfiguredInputs } from '../hooks'
import { getFormatter, getInput } from '../maps'
import { getValidationRules } from '../validation'
import { ErrorMessage } from './ErrorMessage'

export type QuestionLoopInputFormMeta = {
  inputs?: Array<InputConfig>
  summary?: Array<string>
}

type ValidationRule = number | string | boolean

interface QuestionLoopInputFormProps {
  name: string
  type: string
  meta: QuestionLoopInputFormMeta
  validation?: Record<string, ValidationRule | Record<string, ValidationRule>>
  errorMessageClass?: string

  tKey: TKeyBuilder
  t: TFunction
}

export const QuestionLoop: FunctionComponent<QuestionLoopInputFormProps> = ({
  name,
  type,
  validation,
  meta: { inputs, summary },
  errorMessageClass,
  tKey,
  t,
}) => {
  const { control, trigger, watch, resetField } = useFormContext()
  const { fields, append, remove, move } = useFieldArray({
    name,
    control,
  })

  const watchedValue = watch(name)
  const [hasAnswers, setHasAnswers] = useState(
    isNil(watchedValue) ? undefined : Boolean(watchedValue.length),
  )
  const [showInputs, setShowInputs] = useState(false)

  const prefix = `${name}.${fields.length - 1}`
  const configuredInputs = useConfiguredInputs(inputs ?? [], {
    inputValue: { prefix },
  })
  const questionLoopField = fields[fields.length - 1]

  const clearQuestionLoop = useCallback(() => {
    // prevents from populating new first question loop with saved data
    resetField(name, { defaultValue: null })
  }, [name, resetField])

  const onChangeHasAnswers = useCallback(
    (onChange: (it: boolean) => void) => (answer: boolean) => {
      setHasAnswers(answer)

      if (!answer) {
        clearQuestionLoop()
        remove()
        setShowInputs(false)
      } else if (fields.length === 0) {
        const blankFields =
          inputs?.reduce((acc, item) => ({ ...acc, [item.name]: undefined }), {}) ?? {}
        append(blankFields)
        setShowInputs(true)
      }

      onChange(answer)
    },
    [fields, remove, inputs, append, clearQuestionLoop],
  )

  const onAppend = useAsyncCallback(async () => {
    const isValid = await trigger(inputs?.map((input) => `${prefix}.${input.name}`))

    if (isValid) {
      append({})
      setShowInputs(true)
    }
  })

  const onConfirm = useAsyncCallback(async () => {
    const isValid = await trigger(
      inputs?.map((input) => `${name}.${fields.length - 1}.${input.name}`),
    )

    if (isValid) {
      // todo: why do we need this?
      move(fields.length - 1, fields.length - 1)
      setShowInputs(false)
    }
  })

  const onCancel = useCallback(() => {
    setShowInputs(false)
    if (fields.length === 1) {
      setHasAnswers(false)
    }
    remove(fields.length - 1)
  }, [fields, remove, setHasAnswers, setShowInputs])

  const onRemove = useCallback(
    (index: number) => () => {
      if (fields.length === 1) {
        clearQuestionLoop()
        setHasAnswers(false)
      }
      remove(index)
    },
    [fields, remove, setHasAnswers, clearQuestionLoop],
  )

  const summaryGroups = useMemo(() => {
    const groups = showInputs ? fields.slice(0, fields.length - 1) : fields
    return groups.map((g) => ({
      id: g.id,
      fields:
        inputs?.filter((i) => (summary?.includes(i.name) && !isNil(g[i.name])) ?? true) ??
        [],
    }))
  }, [fields, inputs, showInputs, summary])

  return (
    <Box>
      <Box mb={mdBumps}>
        <Controller
          name={`questionLoops-${name}`}
          control={control}
          defaultValue={hasAnswers}
          rules={{
            validate: getValidationRules(type, validation ?? {}),
          }}
          render={({ field: { name, onChange } }) => (
            <BooleanInput
              name={name}
              value={hasAnswers}
              onSelect={onChangeHasAnswers(onChange)}
            />
          )}
        />

        <ErrorMessage
          name={`questionLoops-${name}`}
          tKey={tKey}
          t={t}
          tName='required'
          pt={smBumps}
          className={errorMessageClass}
        />
      </Box>

      {Boolean(summaryGroups.length) && (
        <Flex
          flexDirection='column'
          alignItems='flex-start'
          variant='raised'
          mb={mdBumps}
        >
          {summaryGroups.map((group, index) => (
            <Flex
              key={group.id}
              borderBottomWidth={index === fields.length - 1 ? 0 : 1}
              width={1}
              p={mdBumps}
              gap={mdBumps}
            >
              <Flex
                width={1}
                gap={[2, 0]}
                flexDirection='column'
                alignItems='flex-start'
                justifyContent='flex-start'
              >
                {group.fields.map((input, inputIndex) => {
                  const Formatter = getFormatter(input.type)
                  return (
                    <Controller
                      key={`${group.id}-${inputIndex}`}
                      control={control}
                      name={`${name}.${index}.${input.name}`}
                      defaultValue={group?.[input.name]}
                      render={({ field: { value } }) => (
                        <Typo fontFamily='input'>
                          <Typo
                            fontFamily='input'
                            fontWeight='bold'
                            display={['block', 'inline']}
                            pr={1}
                          >
                            {t(tKey(name, 'inputs', input.name, 'summary'))}:
                          </Typo>
                          <Formatter input={input} value={value} t={t} />
                        </Typo>
                      )}
                    />
                  )
                })}
              </Flex>
              <Box>
                <TertiaryButton layout='small' onClick={onRemove(index)}>
                  Remove
                </TertiaryButton>
              </Box>
            </Flex>
          ))}
        </Flex>
      )}

      {fields.length > 0 && !showInputs && (
        <PrimaryButton
          type='button'
          onClick={onAppend.execute}
          loading={onAppend.loading}
          mb={mdBumps}
        >
          Add another
        </PrimaryButton>
      )}

      {showInputs && (
        <Flex
          key={questionLoopField.id}
          px={mdBumps}
          borderWidth={1}
          flexDirection='column'
          alignItems='flex-start'
          gap={mdBumps}
        >
          {configuredInputs?.map(
            (
              { input: { type, meta, validation, options }, id: inputName, isVisible },
              index,
            ) => {
              if (!isVisible) {
                return null
              }
              const Input = getInput(type)
              return (
                <Box key={`${questionLoopField?.id}-${index}`} width={1}>
                  <Box my={mdBumps}>
                    <Heading>{t(tKey(name, 'inputs', inputName, 'header'))}</Heading>
                    {options?.subheader && (
                      <Box mt={xsBumps}>
                        <Typo color='bodySecondary'>
                          {t(tKey(name, 'inputs', inputName, 'subheader'))}
                        </Typo>
                      </Box>
                    )}
                  </Box>

                  <Controller
                    control={control}
                    name={`${prefix}.${inputName}`}
                    defaultValue={questionLoopField?.[inputName]}
                    rules={{
                      validate: getValidationRules(type, validation ?? {}),
                    }}
                    render={({ field: { name: fieldName, value, onChange, onBlur } }) => (
                      <Input
                        name={fieldName}
                        onChange={onChange}
                        value={value}
                        onBlur={onBlur}
                        meta={meta}
                        validation={validation}
                        tKey={TExtends(tKey, name, 'inputs')}
                        tName={inputName}
                        t={t}
                      />
                    )}
                  />

                  <ErrorMessage
                    name={`${prefix}.${inputName}`}
                    tKey={TExtends(tKey, name, 'inputs')}
                    t={t}
                    tName={inputName}
                    className={errorMessageClass}
                    pt={smBumps}
                  />
                </Box>
              )
            },
          )}

          <Box my={mdBumps}>
            <PrimaryButton
              onClick={onConfirm.execute}
              loading={onConfirm.loading}
              mr={2}
              type='button'
            >
              Confirm
            </PrimaryButton>
            <TertiaryButton onClick={onCancel} type='button'>
              Cancel
            </TertiaryButton>
          </Box>
        </Flex>
      )}
    </Box>
  )
}
