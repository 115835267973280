import {
  Flex,
  Heading,
  Icon,
  isValidVRN,
  LicencePlate,
  mdBumps,
  ModalHeader,
  smBumps,
  TertiaryButton,
  useModal,
  VehicleRegistrationInput,
} from '@wrisk/ui-components'
import { isEmpty } from 'lodash'
import React, { FunctionComponent, useCallback, useState } from 'react'

import { Specification, toVehicleDescription } from '../../../../domain'
import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { ActionBar } from '../ActionBar'
import { VehicleDetails } from './VehicleDetails'

export interface VehicleCardConfig {
  vrnRequired?: boolean
}

export interface VehicleCardProps {
  vrn?: string
  specification: Specification
  onChangeVRN?: (vrn: string | undefined) => void
  onChange: () => void
  config?: VehicleCardConfig
}

const tKey = TKey('components.vehicle-card')

const VrnChangeModal: FunctionComponent<{
  hide: () => void
  value: string | undefined
  vrnRequired?: boolean
  onChange: (vrn: string | undefined) => void
  config?: VehicleCardConfig
}> = ({ value, onChange, hide, config }) => {
  const { t } = useWriskTranslation()

  const [vrn, setVrn] = useState<string | undefined>(value)

  const onConfirm = useCallback(() => {
    onChange(vrn || undefined)
    hide()
  }, [hide, onChange, vrn])

  const disabled = config?.vrnRequired
    ? !isValidVRN(vrn)
    : !isEmpty(vrn) && !isValidVRN(vrn)

  const editable = config?.vrnRequired ? 'mandatory' : 'optional'

  return (
    <Flex
      alignItems='flex-start'
      flexDirection='column'
      justifyContent='flex-start'
      rowGap={mdBumps}
    >
      <ModalHeader
        header={t(tKey('vehicleRegistration', editable, 'header'))}
        subheader={t(tKey('vehicleRegistration', editable, 'subheader'), {
          defaultValue: null,
        })}
      />
      <VehicleRegistrationInput
        onChange={setVrn}
        value={vrn}
        placeholder={t<string>(tKey('vehicleRegistration', 'placeholder'))}
      />

      <ActionBar tKey={tKey} onConfirm={onConfirm} disabled={disabled} />
    </Flex>
  )
}

export const VehicleCard: FunctionComponent<VehicleCardProps> = ({
  specification,
  vrn,
  onChange,
  onChangeVRN,
  config,
}) => {
  const { t } = useWriskTranslation()

  const modal = useModal()

  const onEditVrn = useCallback(() => {
    if (onChangeVRN) {
      modal.show({
        variant: 'default',
        content: (
          <VrnChangeModal
            hide={modal.hide}
            value={vrn}
            onChange={onChangeVRN}
            config={config}
          />
        ),
      })
    }
  }, [onChangeVRN, modal, vrn, config])

  return (
    <Flex
      width={1}
      variant='raised'
      p={mdBumps}
      flexWrap='wrap'
      data-testid='vehicle-card'
    >
      <Flex width={1} marginBottom={smBumps} alignItems='start'>
        <Heading typoSize='md' pb={1} mr={smBumps}>
          {toVehicleDescription(specification)}
        </Heading>
        <TertiaryButton
          style={{ whiteSpace: 'nowrap' }}
          data-testid='vehicle-change'
          layout='small'
          onClick={onChange}
        >
          {t(tKey('actions.change'))}
        </TertiaryButton>
      </Flex>
      <Flex justifyContent='flex-start' alignItems='stretch' columnGap={2}>
        <LicencePlate
          licencePlate={vrn ?? t<string>(tKey('unknown'))}
          variant={vrn ? 'default' : 'unknown'}
        />
        {onChangeVRN && (
          <TertiaryButton onClick={onEditVrn} title='edit' layout='small'>
            <Icon icon='edit' size='small' />
          </TertiaryButton>
        )}
      </Flex>
      <VehicleDetails vehicle={specification} mt={3} />
    </Flex>
  )
}
