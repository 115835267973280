import { isNil } from 'lodash'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { usePrevious } from '../../../../util/usePrevious'
import {
  VehicleCard,
  VehicleCardConfig,
  VehicleTabs,
  VehicleTabsConfig,
} from '../../../organisms/form'
import { FormInputProps } from '../../types'
import { VehicleInputValue } from './types'

export const VehicleTabsFormInput: FunctionComponent<
  FormInputProps<VehicleInputValue, VehicleTabsConfig & VehicleCardConfig>
> = ({ t, tKey, tName, value, onChange, meta }) => {
  const previous = usePrevious(value)

  const onReset = useCallback(() => onChange(null), [onChange])

  const onChangeVRN = useCallback(
    (vrn: string | undefined) =>
      onChange({
        ...value,
        vrn,
      }),
    [onChange, value],
  )

  const currentOrPrevious = value ?? previous

  const initialTab = useMemo(
    () => (isNil(currentOrPrevious?.regLookup) || currentOrPrevious.regLookup ? 0 : 1),
    [currentOrPrevious],
  )

  return value?.specification ? (
    <VehicleCard
      specification={value.specification}
      vrn={value.vrn}
      onChange={onReset}
      onChangeVRN={onChangeVRN}
      config={meta}
    />
  ) : (
    <VehicleTabs
      t={t}
      tKey={tKey}
      tName={tName}
      config={meta}
      initialTab={initialTab}
      value={currentOrPrevious}
      onChange={onChange}
    />
  )
}
