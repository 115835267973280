import { Box, BoxProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  Specification,
  toVehicleEngine,
  toVehicleEnginePower,
  toVehicleFuelDescription,
} from '../../../../domain'
import { AttributeRow } from './AttributeRow'

interface VehicleDetailsProps extends BoxProps {
  vehicle: Specification
}

export const VehicleDetails: FunctionComponent<VehicleDetailsProps> = ({
  vehicle,
  ...props
}) => (
  <Box width={1} {...props}>
    <AttributeRow name='Manufactured' value={vehicle.year.toString()} />
    <AttributeRow name='Fuel' value={toVehicleFuelDescription(vehicle)} />
    <AttributeRow name='Electric type' value={vehicle.electricVehicleType} />
    <AttributeRow name='Body style' value={vehicle.bodyStyle} />
    <AttributeRow name='Transmission' value={vehicle.transmission} />
    <AttributeRow name='Drive train' value={vehicle.driveTrain} />
    <AttributeRow name='Engine' value={toVehicleEngine(vehicle)} />
    <AttributeRow name='Performance' value={toVehicleEnginePower(vehicle)} />
  </Box>
)
