import { isValidVRN, PrimaryButton, VehicleRegistrationInput } from '@wrisk/ui-components'
import { negate } from 'lodash'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'

interface VehicleLookupInputProps {
  loading: boolean
  value?: string
  onSearch: (vrn: string) => void
}

const tKey = TKey('components.vehicle-lookup')

const isInvalidVRN = negate(isValidVRN)

export const VehicleLookupInput: FunctionComponent<VehicleLookupInputProps> = ({
  value,
  loading,
  onSearch,
}) => {
  const { t } = useWriskTranslation()

  const [vrn, setVRN] = useState(value ?? '')

  const disabled = useMemo(() => isInvalidVRN(vrn), [vrn])

  const onSubmit = useCallback(() => {
    if (!disabled) onSearch(vrn)
  }, [disabled, onSearch, vrn])

  return (
    <VehicleRegistrationInput
      name='vehicleRegistration'
      value={vrn}
      placeholder={t<string>(tKey('placeholder'))}
      onChange={setVRN}
      onSubmit={onSubmit}
    >
      <PrimaryButton
        data-testid='vehicle-search'
        disabled={disabled}
        loading={loading}
        layout='small'
        whiteSpace='nowrap'
        onClick={onSubmit}
      >
        {t(tKey('actions.search'))}
      </PrimaryButton>
    </VehicleRegistrationInput>
  )
}
