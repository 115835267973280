import { compact, isNil, negate } from 'lodash'

import { toFormattedDate } from '../util/date'
import { Specification } from './types'

export const toVehicleDescription = ({ make, model, trim }: Specification): string =>
  compact([make, model, trim]).join(' ')

export const toVehicleFuelDescription = ({
  primaryFuel,
  secondaryFuel,
}: Specification): string =>
  secondaryFuel ? `${primaryFuel}/${secondaryFuel}` : primaryFuel

export const toVehicleYearRange = ({
  rangeStartYear,
  rangeEndYear,
}: Specification): string => `${rangeStartYear}-${rangeEndYear ?? 'Present'}`

export const toVehicleManufacturePeriod = ({
  startDate,
  endDate,
}: Specification): string | undefined =>
  startDate && endDate
    ? `${toFormattedDate(startDate)} to ${toFormattedDate(endDate)}`
    : startDate
    ? `${toFormattedDate(startDate)} onwards`
    : undefined

const toEngineCapacityInLitres = (capacity: number | null | undefined) =>
  capacity ? `${(Math.round(capacity / 100.0) / 10).toFixed(1)}-litre` : undefined

export const toVehicleEngine = ({
  engineCapacity,
  engineLabel,
}: Specification): string | undefined =>
  [toEngineCapacityInLitres(engineCapacity), engineLabel]
    .filter(negate(isNil))
    .join(' ') || undefined

export const toVehicleEnginePower = ({
  enginePower,
}: Specification): string | undefined =>
  enginePower ? `${Math.round(enginePower * 1.01387)} PS` : undefined

export const isVehicleOfMake =
  (makes: Array<string | undefined> | undefined) =>
  (specification: Specification | null | undefined): Specification | null =>
    (makes
      ? makes.includes(specification?.make)
        ? specification
        : null
      : specification) ?? null
