import deepEqual from 'deep-equal'
import jp from 'jsonpath'
import { isArray } from 'lodash'

import { Data, JsonUpdate } from '../../domain'
import {
  AdjustmentInputConfig,
  AdjustmentUpdate,
  AssetChangeConfig,
  NestedUpdate,
  ProfileChangeConfig,
  QuoteChangeConfig,
  Update,
} from '../../state/configuration'

export const isNestedUpdate = (update: Update): update is NestedUpdate =>
  Object.hasOwn(update, 'id')

export const toData = (data: Data, update: AdjustmentUpdate): Data =>
  isArray(update)
    ? update.reduce(
        (acc, update) => ({
          ...acc,
          [update.id]: jp.value(data, update.path),
        }),
        {},
      )
    : jp.value(data, update.path)

const getUpdateData = (
  input: AdjustmentInputConfig,
  update: NestedUpdate | Update,
  data: Data,
) => {
  return isNestedUpdate(update) ? data[input.name]?.[update.id] : data[input.name]
}

export const toUpdates =
  (existingData: Data, data: Data) =>
  (
    input: AdjustmentInputConfig<
      ProfileChangeConfig | AssetChangeConfig | QuoteChangeConfig
    >,
  ): JsonUpdate[] => {
    const { adjustment } = input

    const updates = isArray(adjustment.update) ? adjustment.update : [adjustment.update]

    return updates
      .filter(
        (it) =>
          !deepEqual(
            getUpdateData(input, it, existingData),
            getUpdateData(input, it, data),
          ),
      )
      .map((it) => ({
        path: it.path,
        value: getUpdateData(input, it, data),
        attribute: it.attribute,
      }))
  }

export const hasUpdates =
  (existingData: Data, data: Data) => (input: AdjustmentInputConfig) =>
    !deepEqual(existingData[input.name], data[input.name])
