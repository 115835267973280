import React, { FocusEventHandler, FunctionComponent } from 'react'

import { Box } from '../atoms'
import { useNotifyingState } from '../extensions'
import {
  DateInput,
  DateInputs,
  fromInputsToIsoDate,
  fromIsoDateToInputs,
} from './DateInput'
import { InputMessage } from './InputMessage'

export interface DateFeedbackInputProps {
  error: string | undefined
  onBlur?: FocusEventHandler
  onChange: (date: string | undefined) => void
  initialValue?: string
  placeholders?: DateInputs
}

export const DateFeedbackInput: FunctionComponent<DateFeedbackInputProps> = ({
  initialValue,
  onBlur,
  onChange,
  error,
  placeholders,
}) => {
  const initialDateInputs = fromIsoDateToInputs(initialValue)

  const [dateInputs, setDate] = useNotifyingState(initialDateInputs, [
    (d) => onChange(fromInputsToIsoDate(d)),
  ])

  return (
    <Box>
      <DateInput
        onBlur={onBlur}
        onChange={setDate}
        dateInputs={dateInputs}
        placeholders={placeholders}
      />
      {error && <InputMessage>{error}</InputMessage>}
    </Box>
  )
}
