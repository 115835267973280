import {
  Box,
  BoxProps,
  Flex,
  Heading,
  HelpFlexAnchor,
  Icon,
  PasteButton,
  PasteButtonProps,
  smBumps,
  Typo,
  xsBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, ReactNode } from 'react'

import { getHelpLink, useConfig } from '../../../state/configuration'

type OptionalPasteButton =
  | {
      pasteButtonLabel?: undefined
      onPaste?: undefined
    }
  | ({
      pasteButtonLabel: ReactNode
    } & Pick<PasteButtonProps, 'onPaste'>)

export type FormSubHeaderProps = BoxProps &
  OptionalPasteButton & {
    header?: ReactNode
    subheader?: ReactNode
    helpLink?: string
  }

export const FormSubHeader: FunctionComponent<FormSubHeaderProps> = ({
  header,
  helpLink,
  subheader,
  pasteButtonLabel,
  onPaste,
  ...props
}) => {
  const help = useConfig(getHelpLink(helpLink))

  return (
    <Box width={1} {...props}>
      <Flex width={1}>
        {header && (
          <Heading variant='h3' typoSize='base'>
            {header}
          </Heading>
        )}
        {pasteButtonLabel && (
          <PasteButton layout='small' onPaste={onPaste}>
            {pasteButtonLabel}
          </PasteButton>
        )}
        {help && (
          <HelpFlexAnchor
            variant='highlight'
            borderRadius='50%'
            justifyContent='center'
            ml={smBumps}
            p={2}
            href={help}
          >
            <Icon icon='help' size='small' />
          </HelpFlexAnchor>
        )}
      </Flex>
      {subheader && (
        <Typo color='bodySecondary' mt={xsBumps} typoSize='sm'>
          {subheader}
        </Typo>
      )}
    </Box>
  )
}
