import { DateFeedbackInput } from '@wrisk/ui-components'
import React, { FunctionComponent, useMemo } from 'react'

import {
  tDefaults,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { FormInputProps } from '../types'

export const DateFormInput: FunctionComponent<FormInputProps<string>> = ({
  value,
  onBlur,
  onChange,
}) => {
  const { t } = useWriskTranslation()

  const placeholders = useMemo(
    () => ({
      day: t(tDefaults('inputs.date.placeholders.day')),
      month: t(tDefaults('inputs.date.placeholders.month')),
      year: t(tDefaults('inputs.date.placeholders.year')),
    }),
    [t],
  )
  return (
    <DateFeedbackInput
      error={undefined}
      initialValue={value}
      onBlur={onBlur}
      onChange={onChange}
      placeholders={placeholders}
    />
  )
}
