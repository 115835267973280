import { all } from '@wrisk/styled-system'

const forwardedProps = ['data-testid']

const disallowedProps = [
  'displayOn',
  'typoSize',
  'whiteSpace',
  'textTransform',
  'gap',
  'columnGap',
  'rowGap',
  'collapseWhenEmpty',
  'wordWrap',
].concat(all.propNames ?? [])

export const shouldForwardProp = (prop: string): boolean =>
  forwardedProps.includes(prop) || !disallowedProps.includes(prop)
