import {
  AnimatedPage,
  Flex,
  FlexAnchor,
  Header,
  HeaderTitle,
  Icon,
  LinkAnchor,
  MainSection,
  PrimaryButton,
  SideSection,
  StickySidebar,
  WideContainer,
} from '@wrisk/ui-components'
import { isNil } from 'lodash'
import React, { FunctionComponent, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import {
  coverDifferencesToDisclosureChange,
  getCoreAsset,
  getCurrentQuote,
  isAddOn,
  PriceOption,
  RiskProfileStatus,
} from '../../../../domain'
import { useOptionalCovers } from '../../../../hooks/covers'
import { useErrorHandlingAsyncCallback } from '../../../../hooks/errors'
import {
  tDefaults,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { Page, useUpButton } from '../../../../infrastructure/routing'
import { usePrincipal } from '../../../authentication'
import { AddOnSelector } from '../../../organisms/covers'
import { Footer } from '../../../templates'
import { useProduct } from '../../productContext'
import { useProposal } from '../proposalContext'
import { ProposalAddOnsSidebar } from './ProposalAddOnsSidebar'

interface ProposalAddOnsPageProps {
  destination: string
  parent: Page
}

const tKey = TKey('pages.proposal-add-ons')

export const ProposalAddOnsPage: FunctionComponent<ProposalAddOnsPageProps> = ({
  destination,
  parent,
}) => {
  const { t } = useWriskTranslation()

  const navigate = useNavigate()
  const upButton = useUpButton(parent)
  const { apiClient } = usePrincipal()
  const { proposal, setProposal } = useProposal()
  const { product } = useProduct()

  const quote = getCurrentQuote(proposal)
  const { assetCode } = getCoreAsset(quote)

  const originalCovers = useOptionalCovers(quote, product).filter(isAddOn(product))

  const [updatedCovers, setUpdatedCovers] = useState<PriceOption[]>(
    originalCovers.map((cover) => ({
      ...cover,
      active: cover.active || undefined,
    })),
  )

  const disabled = updatedCovers.some(
    (it) => isNil(it.active) && it.ratingStatus === RiskProfileStatus.QUOTE,
  )

  const { loading, execute: onContinue } = useErrorHandlingAsyncCallback(async () => {
    const changes = coverDifferencesToDisclosureChange(
      assetCode,
      updatedCovers,
      originalCovers,
    )

    if (changes.length) {
      setProposal(await apiClient.updateProposal(proposal.proposalCode, { changes }))
    }

    navigate(['..', destination].join('/'))
  })

  return (
    <AnimatedPage pageId='add-ons-page' footer={<Footer />}>
      <WideContainer>
        <Header>
          <Flex width={1}>
            <FlexAnchor
              fontFamily='buttons'
              mb={10}
              as={Link}
              to={upButton?.to ?? '/'}
              data-testid='link-back'
            >
              <Icon size='small' pl={2} icon='chevronRight' rotate={180} />
              <LinkAnchor variant='standalone' as='span' typoSize='sm'>
                {upButton?.text || 'Back'}
              </LinkAnchor>
            </FlexAnchor>
          </Flex>
          <HeaderTitle>{t(tKey('header'))}</HeaderTitle>
        </Header>
      </WideContainer>
      <WideContainer variant='sidebar'>
        <StickySidebar>
          <SideSection>
            <ProposalAddOnsSidebar
              optionalCovers={updatedCovers}
              onContinue={onContinue}
              disabled={disabled}
              loading={loading}
            />
          </SideSection>
        </StickySidebar>
        <MainSection>
          <AddOnSelector
            covers={updatedCovers}
            quote={quote}
            onChange={setUpdatedCovers}
          />
          <ProposalAddOnsSidebar
            displayOn='mobile'
            optionalCovers={updatedCovers}
            disabled={disabled}
            loading={loading}
            onContinue={onContinue}
          />

          <Flex displayOn='desktop' width={1} mt={5} data-testid='button-continue'>
            <PrimaryButton
              layout='fixed'
              loading={loading}
              disabled={disabled}
              onClick={onContinue}
              data-testid='button-continue'
            >
              {t([tKey('actions.continue'), tDefaults('actions.continue')])}
            </PrimaryButton>
          </Flex>
        </MainSection>
      </WideContainer>
    </AnimatedPage>
  )
}
