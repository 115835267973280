import {
  Flex,
  Icon,
  Notification,
  NotificationProps,
  PrimaryButton,
  Typo,
  useModal,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'

import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { useProduct } from '../../productContext'
import { ProposalConfirmationModal } from './ProposalConfirmationModal'
import { useProposalConfirmation } from './useProposalConfirmation'

const tKey = TKey('pages.proposal-configuration')

export const ProposalConfirmationBanner: FunctionComponent<NotificationProps> = (
  props,
) => {
  const { t } = useWriskTranslation()
  const modal = useModal()

  const {
    product: { proposalConfirmation },
  } = useProduct()
  const { data, isNotConfirmed } = useProposalConfirmation()

  const onComplete = useCallback(() => {
    modal.hide()
  }, [modal])

  const openModal = useCallback(() => {
    if (proposalConfirmation) {
      modal.show({
        content: (
          <ProposalConfirmationModal
            inputs={proposalConfirmation.inputs}
            data={data}
            onComplete={onComplete}
          />
        ),
      })
    }
  }, [data, modal, onComplete, proposalConfirmation])

  return isNotConfirmed ? (
    <Notification type='standalone' variant='info' {...props}>
      <Flex
        style={{ gap: '1rem' }}
        width={1}
        flexDirection={['column', 'column', 'row']}
        justifyContent={['center', 'center', 'space-between']}
      >
        <Flex>
          <Icon display={['none', 'none', 'block']} icon='info' mr={3} />
          <Typo>{t(tKey('missing-info'))}</Typo>
        </Flex>
        <PrimaryButton
          style={{ whiteSpace: 'nowrap' }}
          width={[1, 1, 'auto']}
          mt={[3, 3, 0]}
          onClick={openModal}
          data-testid='open-proposal-confirmation-modal'
        >
          {t(tKey('actions.open-proposal-confirmation-modal'))}
        </PrimaryButton>
      </Flex>
    </Notification>
  ) : null
}
