import { Icon, WriskTheme } from '@wrisk/ui-components'

import logo from './assets/icons/logo.svg'
import landroverSubscription from './assets/images/landrover-subscription.png'
import RR3Y2024Q4Background from './assets/images/promo/RR2Y2024Q4/background.jpg'
import RR3Y2024Q2Background from './assets/images/promo/RR3Y2024Q2/background.jpg'
import fontFaces from './fonts'
import icons from './icons'
import illus from './illus'

const landroverColors = {
  white: '#FFFFFF',
  primary600: '#0C121C',
  primary50: '#E9ECEC',
  gray900: '#0C121C',
  gray600: '#4A4F54',
  gray400: '#4A4F5499',
  gray300: '#4A4F544C',
  gray200: '#E4E5E5',
  gray100: '#E9ECEC',
  gray50: '#F8F9F9',
  alert600: '#D2000A',
  alert50: '#FDF2F3',
  success600: '#058A30',
  success50: '#F2F9F5',
  warning600: '#FF7F00',
  warning50: '#FFF9F2',
}

const typoSizes = {
  xxl: {
    fontSize: ['26px', '28px', '32px', '38px'],
    lineHeight: ['28px', '30px', '34px', '38px'],
  },
  xl: {
    fontSize: ['20px', '20px', '24px', '24px'],
    lineHeight: ['24px', '24px', '28px', '28px'],
  },
  lg: {
    fontSize: ['18px', '18px', '20px'],
    lineHeight: ['22px', '22px', '24px'],
  },
  md: {
    fontSize: ['16px', '16px', '18px'],
    lineHeight: ['20px', '20px', '22px'],
  },
  base: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  sm: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  xs: {
    fontSize: '12px',
    lineHeight: '20px',
  },
}

export const landroverTheme: WriskTheme = {
  // Common
  name: 'Land Rover',
  illus,
  fontFaces,
  icons,
  logo: {
    regular: logo,
  },
  breakpoints: ['480px', '768px', '1024px', '1200px', '1600px'],
  space: [
    '0rem', //  0    0px
    '0.25rem', //  1    4px
    '0.5rem', //  2    8px
    '0.75rem', //  3   12px
    '1.0rem', //  4   16px
    '1.25rem', //  5   20px
    '1.5rem', //  6   24px
    '1.75rem', //  7   28px
    '2.0rem', //  8   32px
    '2.5rem', //  9   40px
    '3.0rem', // 10   48px
    '3.5rem', // 11   56px
    '4.0rem', // 12   64px
    '4.5rem', // 13   72px
    '5.0rem', // 14   80px
  ],
  radii: [0, 0, 0, 0],
  lineHeights: ['1em', '1.1em', '1.2em', '1.3em', '1.4em', '1.5em'],
  fonts: {
    body: `'Avenir Next', sans-serif`,
    header: `'Land Rover Web', sans-serif`,
    navbar: `'Avenir Next', sans-serif`,
    buttons: `'Avenir Next', sans-serif`,
    input: `'Avenir Next', sans-serif`,
    licencePlate: `'UKLicencePlate', sans-serif`,
  },
  typoSizes,
  shadows: {
    // TODO (MK): Check usages of shadows
    default: `0 0 0 1px ${landroverColors.gray200}`,
    hover: `0 0 0 1px ${landroverColors.gray400}`, // Can be removed
    input: `0 0 0 2px ${landroverColors.primary600}`,
    inset: `inset -8px 0 0 0 ${landroverColors.white}, inset 8px 0 0 0 ${landroverColors.white}`,
    popupMenu: '0 4px 32px 0 rgba(0, 0, 0, 0.06)',
    radioNested: `inset 0 0 0 1px ${landroverColors.gray200}`,
    radioStandalone: `inset 0 0 0 1px ${landroverColors.gray200}`,
    radioHover: `inset 0 0 0 1px ${landroverColors.gray900}`,
    radioChecked: `inset 0 0 0 2px ${landroverColors.gray900}`,
  },
  transitions: {
    default: 'all 250ms ease',
  },
  navigation: {
    height: ['48px', '48px', '72px'],
  },
  colors: {
    chalk: landroverColors.white,
    background: landroverColors.gray50,
    body: landroverColors.gray900,
    bodySecondary: landroverColors.gray600,
    hyperlink: landroverColors.gray900,
    focus: landroverColors.gray900,
    hover: landroverColors.gray50,

    primaryAccent: landroverColors.primary600,
    secondaryAccent: landroverColors.primary600,

    foregroundPromoBanner: landroverColors.white,
    backgroundPromoBanner: landroverColors.gray900,

    foregroundPromoActivated: landroverColors.white,
    backgroundPromoActivated: landroverColors.success600,

    textOnNavigation: landroverColors.gray900,
    surfaceNavigation: landroverColors.white,

    textInfo: landroverColors.primary600,
    surfaceInfoSubdued: landroverColors.primary50,
    surfaceInfo: landroverColors.primary600,

    textCritical: landroverColors.alert600,
    textOnCritical: landroverColors.alert600,
    surfaceCritical: landroverColors.alert600,
    surfaceCriticalHovered: landroverColors.alert50,
    surfaceCriticalSubdued: landroverColors.alert50,

    textWarning: landroverColors.warning600,
    textOnWarning: landroverColors.warning600,
    surfaceWarning: landroverColors.warning600,
    surfaceWarningSubdued: landroverColors.warning50,

    textHighlight: landroverColors.gray600,
    textOnHighlight: landroverColors.gray600,
    surfaceHighlight: landroverColors.gray100,
    surfaceHighlightSubdued: landroverColors.gray50,

    textSuccess: landroverColors.success600,
    textOnSuccess: landroverColors.success600,
    surfaceSuccess: landroverColors.success600,
    surfaceSuccessSubdued: landroverColors.success50,

    border: landroverColors.gray200,
    divider: landroverColors.gray200,
    inactive: landroverColors.gray600,
    placeholder: landroverColors.gray400,

    textDisabled: landroverColors.gray600,
    surfaceDisabled: landroverColors.gray100,

    textFooter: landroverColors.gray600,
    dividerFooter: landroverColors.gray200,
    surfaceFooter: landroverColors.white,

    radioCheckedBackground: landroverColors.gray900,
    radioCheckedForeground: landroverColors.white,

    dragBoxBorderActive: '#4F46E5',
    dragBoxBorderDefault: '#4B5563',

    progressBackgroundColor: '#9CA3AF',
    progressBarColor: '#4F46E5',
  },
  proposalImages: {
    'landrover-subscription': landroverSubscription,
  },
  promoImages: {
    RR3Y2024Q2: {
      background: RR3Y2024Q2Background as string,
    },
    RR2Y2024Q4: {
      background: RR3Y2024Q4Background as string,
    },
  },
  containerStyle: {
    default: {},
    raised: {
      backgroundColor: landroverColors.white,
      borderStyle: 'solid',
      borderColor: 'border',
      borderWidth: '1px',
    },
    prominent: {},
  },
  // Components
  Button: {
    base: {
      textTransform: 'uppercase',
      transition: 'all 400ms ease',
      letterSpacing: '3px',
    },
    layout: {
      default: {
        px: 6,
        py: '17px',
        fontSize: '10px',
        lineHeight: '14px',
      },
      fixed: {
        px: 6,
        py: '17px',
        fontSize: '10px',
        lineHeight: '14px',
        width: '85%',
        maxWidth: '262px',
      },
      small: {
        px: 3,
        py: '11px',
        fontSize: '10px',
        lineHeight: '14px',
      },
    },
  },
  PrimaryButton: {
    variants: {
      default: {
        bg: landroverColors.primary600,
        color: landroverColors.white,
        '&:hover:not([disabled])': {
          boxShadow: `inset 0 0 0 1px ${landroverColors.primary600}`,
          bg: landroverColors.white,
          color: landroverColors.primary600,
        },
      },
      inverted: {
        bg: landroverColors.white,
        color: landroverColors.gray900,
        '&:hover:not([disabled])': {
          boxShadow: 'none',
          bg: 'rgba(255,255,255,0.36)',
        },
      },
      critical: {
        bg: landroverColors.primary600,
        color: landroverColors.white,
        '&:hover:not([disabled])': {
          boxShadow: `inset 0 0 0 1px ${landroverColors.primary600}`,
          bg: landroverColors.white,
          color: landroverColors.primary600,
        },
      },
    },
  },
  SecondaryButton: {
    variants: {
      default: {
        bg: landroverColors.gray100,
        color: landroverColors.primary600,
        '&:hover:not([disabled])': {
          boxShadow: `inset 0 0 0 1px ${landroverColors.primary600}`,
          bg: landroverColors.white,
          color: landroverColors.primary600,
        },
      },
      inverted: {
        bg: landroverColors.primary600,
        color: landroverColors.gray100,
        '&:hover:not([disabled])': {
          boxShadow: `inset 0 0 0 1px ${landroverColors.white}`,
          bg: landroverColors.primary600,
          color: landroverColors.white,
        },
      },
    },
  },
  TertiaryButton: {
    variants: {
      default: {
        boxShadow: `inset 0 0 0 1px ${landroverColors.gray300}`,
        color: landroverColors.gray900,
        '&:hover:not([disabled])': {
          bg: landroverColors.gray100,
        },
      },
      inverted: {
        bg: landroverColors.primary600,
        color: landroverColors.gray100,
        '&:hover:not([disabled])': {
          boxShadow: `inset 0 0 0 1px ${landroverColors.white}`,
          bg: landroverColors.primary600,
          color: landroverColors.white,
        },
      },
    },
  },
  IconButton: {
    variants: {
      default: {
        '&:hover': {
          backgroundColor: 'hover',
        },
      },
      primary: {
        bg: landroverColors.primary600,
        color: landroverColors.white,
        '&:hover:not([disabled])': {
          boxShadow: `inset 0 0 0 1px ${landroverColors.primary600}`,
          bg: landroverColors.white,
          color: landroverColors.primary600,
          [Icon]: {
            path: {
              fill: landroverColors.primary600,
            },
            ellipse: {
              fill: landroverColors.primary600,
            },
          },
        },
        [Icon]: {
          path: {
            fill: landroverColors.white,
          },
          ellipse: {
            fill: landroverColors.white,
          },
        },
      },
    },
  },
  Heading: {
    default: {
      textTransform: 'uppercase',
    },
    h1: {
      textTransform: 'uppercase',
      letterSpacing: '1.9px',
    },
    h2: {
      textTransform: 'uppercase',
      letterSpacing: '1.2px',
    },
    h3: {
      textTransform: 'uppercase',
      letterSpacing: '1px',
    },
    h4: {
      textTransform: 'uppercase',
      letterSpacing: '0.8px',
    },
  },
  Typo: {
    default: {
      letterSpacing: '0.2px',
    },
    heading: {
      fontFamily: 'header',
      textTransform: 'uppercase',
    },
    buttons: {
      fontFamily: 'buttons',
    },
  },
  TabAnchor: {
    active: {
      textTransform: 'uppercase',
      py: 4,
      color: landroverColors.gray900,
      borderColor: landroverColors.primary600,
      borderBottomWidth: 3,
      fontSize: '11px',
      lineHeight: '16px',
      letterSpacing: '1.76px',
    },
    inactive: {
      textTransform: 'uppercase',
      py: 4,
      color: landroverColors.gray400,
      borderColor: 'transparent',
      borderBottomWidth: 3,
      fontSize: '11px',
      lineHeight: '16px',
      letterSpacing: '1.76px',
      '&:hover': {
        color: landroverColors.gray900,
      },
    },
  },
  LinkAnchor: {
    standalone: {
      textTransform: 'uppercase',
      color: landroverColors.primary600,
      borderColor: 'transparent',
      borderBottomWidth: 1,
      letterSpacing: '2px',
      '&:hover': {
        borderColor: landroverColors.gray900,
        color: landroverColors.gray900,
        borderBottomWidth: 1,
      },
    },
    inline: {
      '&:hover': {
        color: landroverColors.primary600,
        borderColor: landroverColors.primary600,
      },
    },
  },
  FooterAnchor: {
    default: {
      textTransform: 'uppercase',
      letterSpacing: '1px',
      fontSize: '10px',
      lineHeight: '15px',
      fontWeight: 'bold',
      color: landroverColors.gray600,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  Logo: {
    default: {
      height: ['32px', '32px', '47px'],
    },
  },
  NavBar: {
    default: {
      boxShadow: 'rgba(1, 1, 1, 0.1) 0px 1px 4px 0px',
      pr: [2, 2, 4, 4],
    },
  },
  AppName: {
    default: {
      order: 1,
      textTransform: 'uppercase',
      fontFamily: 'header',
      letterSpacing: '3px',
      fontSize: ['12px', '12px', '14px'],
      lineHeight: ['16px', '16px', '20px'],
    },
  },
  HelpText: {
    default: {
      textTransform: 'uppercase',
      fontFamily: 'navbar',
      letterSpacing: '1.76px',
      fontSize: '11px',
      lineHeight: '16px',
    },
  },
  LogoNav: {
    default: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      order: 2,
    },
  },
  MenuBox: {
    default: {
      order: 3,
    },
  },
  NavAnchor: {
    default: {
      height: (theme: WriskTheme): (string | number)[] => theme.navigation.height,
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: landroverColors.gray100,
      },
    },
    inverted: {
      fontWeight: 'bold',
    },
  },
  StyledPremium: {
    default: {},
    inverted: {
      bg: 'transparent',
      color: landroverColors.gray900,
    },
  },
}
