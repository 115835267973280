import {
  AssetDisclosureChange,
  Data,
  getCoreAsset,
  getCurrentQuote,
  PolicyChangeType,
  ProfileDisclosureChange,
  Proposal,
  ProposalAdjustmentRequest,
  QuoteDisclosureChange,
  RequestCoverOptionChange,
  SelectVoluntaryExcessChange,
} from '../../../../domain'
import {
  AdjustmentInputConfig,
  AssetChangeConfig,
  ChangeConfig,
  ChangeType,
  ProfileChangeConfig,
  QuoteChangeConfig,
  RequestCoverOptionChangeConfig,
  StartDateChangeConfig,
  VoluntaryExcessChangeConfig,
} from '../../../../state/configuration'
import { hasUpdates, toUpdates } from '../../helpers'

type ProposalAdjustmentReducer<T extends ChangeConfig> = (
  proposalAdjustment: ProposalAdjustmentRequest,
  input: AdjustmentInputConfig<T>,
) => ProposalAdjustmentRequest

const toProposalAssetDisclosureReducer =
  (
    existingData: Data,
    data: Data,
    proposal: Proposal,
  ): ProposalAdjustmentReducer<AssetChangeConfig> =>
  (proposalAdjustment, input) => {
    const quote = getCurrentQuote(proposal)
    const { assetCode } = getCoreAsset(quote)

    const updates = toUpdates(existingData, data)

    const changes: AssetDisclosureChange[] = updates(input).map((update) => ({
      changeType: PolicyChangeType.ASSET_DISCLOSURE_CHANGE,
      assetCode,
      update,
    }))

    return {
      ...proposalAdjustment,
      changes: [...proposalAdjustment.changes, ...changes],
    }
  }

const toProposalQuoteDisclosureReducer =
  (existingData: Data, data: Data): ProposalAdjustmentReducer<QuoteChangeConfig> =>
  (proposalAdjustment, input) => {
    const updates = toUpdates(existingData, data)

    const changes: QuoteDisclosureChange[] = updates(input).map((update) => ({
      changeType: PolicyChangeType.QUOTE_DISCLOSURE_CHANGE,
      update,
    }))

    return {
      ...proposalAdjustment,
      changes: [...proposalAdjustment.changes, ...changes],
    }
  }

const toProfileDisclosureReducer =
  (existingData: Data, data: Data): ProposalAdjustmentReducer<ProfileChangeConfig> =>
  (proposalAdjustment, input) => {
    const updates = toUpdates(existingData, data)

    const changes: ProfileDisclosureChange[] = updates(input).map((update) => ({
      changeType: PolicyChangeType.PROFILE_DISCLOSURE_CHANGE,
      update,
    }))

    return {
      ...proposalAdjustment,
      changes: [...proposalAdjustment.changes, ...changes],
    }
  }

const toVoluntaryExcessDisclosureReducer =
  (data: Data): ProposalAdjustmentReducer<VoluntaryExcessChangeConfig> =>
  (proposalAdjustment, input) => {
    const change: SelectVoluntaryExcessChange = {
      changeType: PolicyChangeType.SELECT_VOLUNTARY_EXCESS,
      excess: data[input.name],
    }

    return {
      ...proposalAdjustment,
      changes: [...proposalAdjustment.changes, change],
    }
  }

const toStartDateDisclosureReducer =
  (data: Data): ProposalAdjustmentReducer<StartDateChangeConfig> =>
  (proposalAdjustment, input) => ({
    ...proposalAdjustment,
    coverStart: data[input.name],
  })

const toProposalRequestCoverOptionReducer =
  (data: Data): ProposalAdjustmentReducer<RequestCoverOptionChangeConfig> =>
  (proposalAdjustment, question) => {
    const change: RequestCoverOptionChange = {
      changeType: PolicyChangeType.REQUEST_COVER_OPTION,
      coverCode: question.adjustment.coverCode,
      selected: data[question.name],
    }

    return {
      ...proposalAdjustment,
      changes: [...proposalAdjustment.changes, change],
    }
  }

export const toProposalAdjustment =
  (inputs: AdjustmentInputConfig[], applyRating: boolean, proposal: Proposal) =>
  (existingData: Data, data: Data): ProposalAdjustmentRequest => {
    const profileDisclosureReducer = toProfileDisclosureReducer(existingData, data)
    const voluntaryExcessDisclosureReducer = toVoluntaryExcessDisclosureReducer(data)
    const assetDisclosureReducer = toProposalAssetDisclosureReducer(
      existingData,
      data,
      proposal,
    )

    const quoteDisclosureReducer = toProposalQuoteDisclosureReducer(existingData, data)
    const requestCoverOptionReducer = toProposalRequestCoverOptionReducer(data)

    const disclosureReducer = toStartDateDisclosureReducer(data)

    return inputs.filter(hasUpdates(existingData, data)).reduce(
      (proposalAdjustment: ProposalAdjustmentRequest, input) => {
        switch (input.adjustment.changeType) {
          case ChangeType.PROFILE_DISCLOSURE:
            return profileDisclosureReducer(
              proposalAdjustment,
              input as AdjustmentInputConfig<ProfileChangeConfig>,
            )
          case ChangeType.VOLUNTARY_EXCESS:
            return voluntaryExcessDisclosureReducer(
              proposalAdjustment,
              input as AdjustmentInputConfig<VoluntaryExcessChangeConfig>,
            )
          case ChangeType.ASSET_DISCLOSURE:
            return assetDisclosureReducer(
              proposalAdjustment,
              input as AdjustmentInputConfig<AssetChangeConfig>,
            )
          case ChangeType.QUOTE_DISCLOSURE:
            return quoteDisclosureReducer(
              proposalAdjustment,
              input as AdjustmentInputConfig<QuoteChangeConfig>,
            )
          case ChangeType.START_DATE_CHANGE:
            return disclosureReducer(
              proposalAdjustment,
              input as AdjustmentInputConfig<StartDateChangeConfig>,
            )
          case ChangeType.REQUEST_COVER_OPTION:
            return requestCoverOptionReducer(
              proposalAdjustment,
              input as AdjustmentInputConfig<RequestCoverOptionChangeConfig>,
            )
          default:
            throw new Error('Invalid adjustment type')
        }
      },
      {
        applyRating,
        changes: [],
      },
    )
  }
