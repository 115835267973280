import { compose, css, space, SpaceProps, variant } from '@wrisk/styled-system'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

export type LicencePlateProperty = 'default' | 'subtle' | 'unknown'

const StyledLicencePlate = styled.div<Omit<LicencePlateProps, 'licencePlate'>>(
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textWrap: 'nowrap',
    fontFamily: 'UKLicencePlate, sans-serif',
  }),
  variant({
    scale: 'LicencePlate',
    variants: {
      default: {
        backgroundColor: '#ffd067',
        color: 'body',
      },
      subtle: {
        borderColor: 'body',
        borderWidth: 1,
        borderStyle: 'solid',
        color: 'body',
      },
      unknown: {
        borderColor: 'inactive',
        borderWidth: 1,
        borderStyle: 'dashed',
        color: 'inactive',
      },
    },
  }),
  ({ theme }) => {
    return variant({
      prop: 'size',
      variants: {
        default: {
          py: 1,
          px: 4,
          minWidth: '3.5rem',
          borderRadius: 1,
          ...theme.typoSizes.base,
        },
        small: {
          py: 0,
          px: 2,
          borderRadius: 0,
          ...theme.typoSizes.sm,
        },
      },
    })
  },
  compose(space),
)

StyledLicencePlate.defaultProps = {
  variant: 'default',
  size: 'default',
}

export interface LicencePlateProps extends SpaceProps {
  licencePlate: string | undefined
  variant?: LicencePlateProperty
  size?: 'default' | 'small'
}

export const LicencePlate: FunctionComponent<LicencePlateProps> = ({
  licencePlate,
  ...props
}) =>
  licencePlate !== undefined ? (
    <StyledLicencePlate {...props}>{licencePlate}</StyledLicencePlate>
  ) : null
