import { Box } from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'

import { PriceOption, Quote } from '../../../domain'
import { TKey } from '../../../infrastructure/internationalisation'
import { AddOnBoolean } from './AddOnBoolean'

interface AddOnsMainProps {
  quote: Quote
  covers: PriceOption[]
  onChange: (covers: PriceOption[]) => void
  default?: boolean
}

const tKey = TKey('components.add-on-selector')

export const AddOnSelector: FunctionComponent<AddOnsMainProps> = ({
  quote,
  covers,
  onChange,
}) => {
  const onChangeCallback = useCallback(
    (cover: PriceOption) =>
      onChange(covers.map((it) => (it.coverCode === cover.coverCode ? cover : it))),
    [onChange, covers],
  )

  const addOns = covers.map((cover) => (
    <AddOnBoolean
      tKey={tKey}
      key={cover.coverCode}
      quote={quote}
      cover={cover}
      onChange={onChangeCallback}
    />
  ))

  return <Box width={1}>{addOns}</Box>
}
