import {
  AnimatedPage,
  Banner,
  BannerTitle,
  lgBumps,
  mdBumps,
  NotificationContainer,
  PageLoadingIndicator,
  TabItem,
  TabView,
  WideContainer,
  xlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { useApiErrorHandlingAsync } from '../../hooks/auth'
import { TKey, useWriskTranslation } from '../../infrastructure/internationalisation'
import { getProducts, getSchemes, useConfig } from '../../state/configuration'
import { usePolicyholder, usePrincipal } from '../authentication'
import {
  CardMissingNotification,
  DirectDebitMissingNotification,
} from '../organisms/billing'
import { Footer } from '../templates'
import { CreateProposalSections } from './organisms/CreateProposalSections'
import { RefreshProvider } from './organisms/RefreshContext'
import { getPolicyInformation, PoliciesView } from './PoliciesView'
import { getProposalInformation, ProposalsView } from './ProposalsView'

const tKey = TKey('pages.home-authenticated')

export const AuthenticatedHomePage: FunctionComponent = () => {
  const { t } = useWriskTranslation()

  const products = useConfig(getProducts)
  const schemes = useConfig(getSchemes)

  const { apiClient } = usePrincipal()
  const { policyholder } = usePolicyholder()
  const { loading, result, execute } = useApiErrorHandlingAsync(
    () =>
      Promise.all([
        apiClient.getProposals(),
        apiClient.getPolicies(),
        apiClient.getPaymentMethods(),
        apiClient.getCustomerAccounts(),
      ]),
    [],
  )

  if (loading || !result) {
    return <PageLoadingIndicator />
  }

  const [proposals, policies, paymentMethods, customerAccounts] = result

  const proposalInformation = getProposalInformation(schemes, products)(proposals)
  const policyInformation = getPolicyInformation(
    schemes,
    products,
    customerAccounts,
  )(policies)

  const items: TabItem[] = [
    {
      component: () => (
        <PoliciesView mt={lgBumps} policyInformation={policyInformation} width={1} />
      ),
      header: t(tKey('tabs', 'policies'), { count: policyInformation.length }),
    },
    {
      component: () => (
        <ProposalsView mt={lgBumps} proposalInformation={proposalInformation} width={1} />
      ),
      header: t(tKey('tabs', 'proposals'), { count: proposalInformation.length }),
    },
  ]

  return (
    <RefreshProvider refresh={execute}>
      <AnimatedPage pageId='home-page' footer={<Footer />}>
        <Banner>
          <WideContainer flexDirection='column'>
            <BannerTitle>{t(tKey('header'), { policyholder })}</BannerTitle>
          </WideContainer>
        </Banner>

        <WideContainer>
          <NotificationContainer mb={xlBumps}>
            <DirectDebitMissingNotification
              paymentMethods={paymentMethods}
              customerAccounts={customerAccounts}
            />
            <CardMissingNotification
              paymentMethods={paymentMethods}
              customerAccounts={customerAccounts}
            />
          </NotificationContainer>

          {policyInformation.length && proposalInformation.length ? (
            <TabView width={1} items={items} mb={mdBumps} />
          ) : policyInformation.length ? (
            <PoliciesView policyInformation={policyInformation} width={1} mb={xlBumps} />
          ) : proposalInformation.length ? (
            <ProposalsView
              proposalInformation={proposalInformation}
              width={1}
              mb={xlBumps}
            />
          ) : undefined}

          <CreateProposalSections
            schemes={schemes}
            proposals={proposals}
            policies={policies}
          />
        </WideContainer>
      </AnimatedPage>
    </RefreshProvider>
  )
}
