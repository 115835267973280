import React, { ChangeEvent, FunctionComponent, KeyboardEvent, useCallback } from 'react'

import { InputWrapper, InputWrapperProps, TextInput } from '../atoms'
import { formatPartialLicencePlate } from '../extensions'

export interface VehicleRegistrationInputInputProps
  extends Omit<InputWrapperProps, 'onChange' | 'onSubmit'> {
  disabled?: boolean
  value?: string
  onChange: (value: string) => void
  onSubmit?: (value?: string) => void
  placeholder?: string
}

export const VehicleRegistrationInput: FunctionComponent<
  VehicleRegistrationInputInputProps
> = ({ disabled, value, onChange, placeholder, onSubmit, name, ...props }) => {
  const onInputChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      onChange(formatPartialLicencePlate(target.value))
    },
    [onChange],
  )

  const onInputKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event?.preventDefault?.()
        onSubmit?.(value)
      }
    },
    [value, onSubmit],
  )

  return (
    <InputWrapper {...props}>
      <TextInput
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        value={value ?? ''}
        onChange={onInputChange}
        onKeyDown={onInputKeyPress}
        variant='ghost'
        fontFamily='licencePlate'
        width={1}
        typoSize='md'
      />
      {props.children}
    </InputWrapper>
  )
}
