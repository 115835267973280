import { LinkAnchor, TabView } from '@wrisk/ui-components'
import { TFunction } from 'i18next'
import React, { FunctionComponent, useCallback, useState } from 'react'
import { Trans } from 'react-i18next'

import { Specification } from '../../../../domain'
import { TKeyBuilder } from '../../../../infrastructure/internationalisation'
import { VehicleLookup } from '../VehicleLookup'
import { VehicleSelect, VehicleSelectValue } from '../VehicleSelect'
import { VehicleTabsConfig } from './types'

export type VehicleTabsValue = Partial<{
  specification: Specification
  vrn: string
  regLookup: boolean
}>

export interface VehicleTabsProps {
  t: TFunction
  tKey: TKeyBuilder
  tName: string
  initialTab: number
  config?: VehicleTabsConfig
  value?: VehicleTabsValue
  onChange: (value?: VehicleTabsValue) => void
}

export const VehicleTabs: FunctionComponent<VehicleTabsProps> = ({
  t,
  tKey,
  tName,
  initialTab,
  config,
  value,
  onChange,
}) => {
  const [tab, setTab] = useState(initialTab)

  const onLookup = useCallback(
    (specification?: Specification) => {
      onChange({
        specification,
        regLookup: true,
        vrn: specification?.vrn ?? undefined,
      })
    },
    [onChange],
  )

  const onSelect = useCallback(
    (value?: VehicleSelectValue) => {
      onChange({ ...value, regLookup: false })
    },
    [onChange],
  )

  const onNotMyVehicleClick = useCallback(() => setTab(1), [])

  const tabItems = [
    {
      header: t(tKey(tName, 'tabHeaders.searchReg')),
      component: () => (
        <VehicleLookup
          config={config}
          value={value?.vrn ?? undefined}
          onSearch={onLookup}
          error={
            <Trans
              t={t}
              i18nKey={tKey(tName, 'validation', 'notFoundTabs')}
              components={{
                cta: <LinkAnchor variant='inline' onClick={onNotMyVehicleClick} />,
              }}
            />
          }
        />
      ),
    },
    {
      header: t(tKey(tName, 'tabHeaders.selectVehicle')),
      component: () => (
        <VehicleSelect config={config} value={value} onChange={onSelect} />
      ),
    },
  ]

  return (
    <TabView width={1} key={tab} items={tabItems} tabIndex={tab} onTabChange={setTab} />
  )
}
