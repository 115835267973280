import { isNil } from 'lodash'

import { Policy, ProposalStatus, RenewalPolicy, ReviewType } from './types'

export const isRenewalPolicy = (policy: Policy): policy is RenewalPolicy =>
  !isNil(policy.renewalProposal)

export const isPolicyRenewalBound = (policy: Policy): policy is RenewalPolicy =>
  isRenewalPolicy(policy) && policy.renewalProposal.status === ProposalStatus.POLICY

export const isPolicyNotRenewable = (policy: Policy) =>
  policy.reviewType === ReviewType.NEVER ||
  policy.policyDetail.renewalStopped ||
  !policy.policyDetail.autoRenewalEnabled
