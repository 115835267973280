import {
  AssetDisclosureChange,
  Data,
  getCoreAsset,
  getPolicyVersion,
  Policy,
  PolicyAdjustmentRequest,
  PolicyChangeType,
  ProfileDisclosureChange,
  QuoteDisclosureChange,
  RequestCoverOptionChange,
} from '../../../../domain'
import {
  AdjustmentInputConfig,
  AssetChangeConfig,
  ChangeType,
  ProfileChangeConfig,
  QuoteChangeConfig,
  RequestCoverOptionChangeConfig,
} from '../../../../state/configuration'
import { hasUpdates, toUpdates } from '../../helpers'

const toPolicyAssetDisclosureChange =
  (existingData: Data, data: Data, policy: Policy) =>
  (
    policyAdjustment: PolicyAdjustmentRequest,
    input: AdjustmentInputConfig<AssetChangeConfig>,
  ): PolicyAdjustmentRequest => {
    const version = getPolicyVersion(policy)
    const { assetCode } = getCoreAsset(version.quote)

    const updates = toUpdates(existingData, data)

    const changes: AssetDisclosureChange[] = updates(input).map((update) => ({
      changeType: PolicyChangeType.ASSET_DISCLOSURE_CHANGE,
      assetCode,
      update,
    }))

    return {
      ...policyAdjustment,
      changes: [...policyAdjustment.changes, ...changes],
    }
  }

const toPolicyProfileDisclosureReducer =
  (existingData: Data, data: Data) =>
  (
    policyAdjustment: PolicyAdjustmentRequest,
    input: AdjustmentInputConfig<ProfileChangeConfig>,
  ): PolicyAdjustmentRequest => {
    const updates = toUpdates(existingData, data)

    const changes: ProfileDisclosureChange[] = updates(input).map((update) => ({
      changeType: PolicyChangeType.PROFILE_DISCLOSURE_CHANGE,
      update,
    }))

    return {
      ...policyAdjustment,
      changes: [...policyAdjustment.changes, ...changes],
    }
  }

const toStartDateChangeReducer =
  (data: Data) =>
  (
    policyAdjustment: PolicyAdjustmentRequest,
    input: AdjustmentInputConfig,
  ): PolicyAdjustmentRequest => {
    return {
      ...policyAdjustment,
      adjustmentType: 'SDA',
      effectiveAt: data[input.name],
    }
  }

const toPolicyQuoteDisclosureReducer =
  (existingData: Data, data: Data) =>
  (
    policyAdjustment: PolicyAdjustmentRequest,
    input: AdjustmentInputConfig<QuoteChangeConfig>,
  ): PolicyAdjustmentRequest => {
    const updates = toUpdates(existingData, data)

    const changes: QuoteDisclosureChange[] = updates(input).map((update) => ({
      changeType: PolicyChangeType.QUOTE_DISCLOSURE_CHANGE,
      update,
    }))

    return {
      ...policyAdjustment,
      changes: [...policyAdjustment.changes, ...changes],
    }
  }

const toPolicyRequestCoverOptionReducer =
  (data: Data) =>
  (
    policyAdjustment: PolicyAdjustmentRequest,
    question: AdjustmentInputConfig<RequestCoverOptionChangeConfig>,
  ): PolicyAdjustmentRequest => {
    const change: RequestCoverOptionChange = {
      changeType: PolicyChangeType.REQUEST_COVER_OPTION,
      coverCode: question.adjustment.coverCode,
      selected: data[question.name],
    }

    return {
      ...policyAdjustment,
      changes: [...policyAdjustment.changes, change],
    }
  }

export const toPolicyAdjustment =
  (inputs: AdjustmentInputConfig[], policy: Policy) =>
  (
    existingData: Data,
    updatedData: Data,
    effectiveAt?: string,
  ): PolicyAdjustmentRequest => {
    const profileDisclosureReducer = toPolicyProfileDisclosureReducer(
      existingData,
      updatedData,
    )
    const assetDisclosureReducer = toPolicyAssetDisclosureChange(
      existingData,
      updatedData,
      policy,
    )
    const quoteDisclosureReducer = toPolicyQuoteDisclosureReducer(
      existingData,
      updatedData,
    )

    const requestCoverOptionReducer = toPolicyRequestCoverOptionReducer(updatedData)

    const startDateChangeReducer = toStartDateChangeReducer(updatedData)

    return inputs.filter(hasUpdates(existingData, updatedData)).reduce(
      (policyAdjustment: PolicyAdjustmentRequest, question) => {
        switch (question.adjustment.changeType) {
          case ChangeType.PROFILE_DISCLOSURE:
            return profileDisclosureReducer(
              policyAdjustment,
              question as AdjustmentInputConfig<ProfileChangeConfig>,
            )
          case ChangeType.ASSET_DISCLOSURE:
            return assetDisclosureReducer(
              policyAdjustment,
              question as AdjustmentInputConfig<AssetChangeConfig>,
            )
          case ChangeType.QUOTE_DISCLOSURE:
            return quoteDisclosureReducer(
              policyAdjustment,
              question as AdjustmentInputConfig<QuoteChangeConfig>,
            )
          case ChangeType.START_DATE_CHANGE:
            return startDateChangeReducer(policyAdjustment, question)
          case ChangeType.REQUEST_COVER_OPTION:
            return requestCoverOptionReducer(
              policyAdjustment,
              question as AdjustmentInputConfig<RequestCoverOptionChangeConfig>,
            )
          default:
            throw new Error('Invalid adjustment type')
        }
      },
      {
        policyId: policy.policyId,
        adjustmentType: 'MTA',
        changes: [],
        ...(effectiveAt && { effectiveAt }),
      },
    )
  }
