import { Box, BoxProps, InputMessage } from '@wrisk/ui-components'
import React, { FunctionComponent, ReactNode } from 'react'

import { isVehicleOfMake, Specification } from '../../../../domain'
import { useApiErrorHandlingAsyncCallback } from '../../../../hooks/auth'
import { usePrincipal } from '../../../authentication'
import { VehicleLookupInput } from './VehicleLookupInput'

export interface VehicleLookupConfig {
  makes?: Array<string | undefined>
}

interface VehicleLookupProps extends BoxProps {
  value?: string
  onSearch: (value?: Specification) => void
  config?: VehicleLookupConfig
  error: ReactNode
}

export const VehicleLookup: FunctionComponent<VehicleLookupProps> = ({
  value,
  onSearch,
  error,
  config,
  ...props
}) => {
  const { apiClient } = usePrincipal()

  // This uses the distinction between null and undefined to decide on whether a call has
  // been made and returned no results (null) or no call has been made at all (undefined)
  const { result, loading, execute } = useApiErrorHandlingAsyncCallback(
    async (vrn: string) => {
      const vehicle = await apiClient
        .lookupVehicle(vrn)
        .then(isVehicleOfMake(config?.makes))

      onSearch(vehicle ?? undefined)

      return vehicle
    },
  )

  return (
    <Box width={1} {...props}>
      <VehicleLookupInput
        value={value ?? undefined}
        loading={loading}
        onSearch={execute}
      />
      {result === null && <InputMessage>{error}</InputMessage>}
    </Box>
  )
}
