import { ExpandableSection, ExpandableSectionProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { Quote } from '../../../domain'
import { useIncludedCovers } from '../../../hooks/covers'
import { getExposuresContentBaseUrl, useConfig } from '../../../state/configuration'
import { useProduct } from '../../product/productContext'
import { CoverEndorsements } from './CoverEndorsements'
import { IncludedCoverItem } from './IncludedCoverItem'

export interface IncludedCoversExpandableProps extends ExpandableSectionProps {
  quote: Quote
}

export const IncludedCoversExpandable: FunctionComponent<
  IncludedCoversExpandableProps
> = ({ quote, header, ...props }) => {
  const { scheme, product } = useProduct()

  const includedCovers = useIncludedCovers(product)
  const contentBaseUrl = useConfig(getExposuresContentBaseUrl(scheme.schemeCode))

  const coverItems = includedCovers.map((it) => {
    return (
      <IncludedCoverItem
        key={it}
        cover={it}
        width={1}
        contentBaseUrl={contentBaseUrl}
        typoSize='sm'
        iconSize='small'
      />
    )
  })

  const endorsementsSection = <CoverEndorsements quote={quote} my={3} />

  return (
    <ExpandableSection header={header} {...props}>
      {coverItems}
      {endorsementsSection}
    </ExpandableSection>
  )
}
