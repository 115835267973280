import {
  Box,
  Flex,
  FlexAnchor,
  FlexAnchorProps,
  HelpLinkIFrame,
  Icon,
  ModalHeader,
  smBumps,
  TertiaryButton,
  Typo,
  useModal,
} from '@wrisk/ui-components'
import React, { FunctionComponent, MouseEvent, useCallback } from 'react'
import { Trans } from 'react-i18next'

import { PriceOption, Quote, RiskProfileStatus } from '../../../domain'
import {
  tCovers,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import {
  getOptionalCoverLink,
  getOptionalCoversContentBaseUrl,
  useConfig,
} from '../../../state/configuration'
import { useProduct } from '../../product/productContext'

export interface OptionalFlexAnchorProps
  extends Omit<FlexAnchorProps, 'onChange' | 'ref'> {
  quote: Quote
  cover: PriceOption
  onChange?: (cover: PriceOption) => void
}

const tKey = TKey('components.optional-flex-anchor')

export const OptionalFlexAnchor: FunctionComponent<OptionalFlexAnchorProps> = ({
  quote,
  cover,
  onChange,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const modal = useModal()

  const { scheme } = useProduct()

  const inQuote = cover.ratingStatus === RiskProfileStatus.QUOTE

  const icon = inQuote && cover.active ? 'tick' : 'cross'
  const color = inQuote && cover.active ? 'body' : 'inactive'

  const contentBaseUrl = useConfig(getOptionalCoversContentBaseUrl(scheme.schemeCode))
  const link = getOptionalCoverLink(contentBaseUrl, quote.wordingVersion, cover)

  const onClick = useCallback(() => {
    switch (cover.ratingStatus) {
      case RiskProfileStatus.QUOTE:
        return modal.show({
          content: <HelpLinkIFrame src={link} />,
          variant: 'iframe',
        })
      case RiskProfileStatus.INSUFFICIENT_ACCURACY:
      case RiskProfileStatus.REFERRAL:
        return modal.show({
          content: (
            <Box>
              <ModalHeader header={t(tCovers(cover.coverCode, 'name'))} />
              <Typo>
                <Trans t={t} i18nKey={tCovers(cover.coverCode, 'unavailable')} />
              </Typo>
            </Box>
          ),
        })
    }
  }, [cover, link, modal, t])

  const onEdit = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.stopPropagation()
      onChange?.(cover)
    },
    [cover, onChange],
  )

  return (
    <FlexAnchor
      variant='highlight'
      py={3}
      px={smBumps}
      columnGap={2}
      onClick={onClick}
      {...props}
    >
      <Flex justifyContent='flex-start'>
        <Icon icon={icon} color={color} mr={smBumps} />
        <Typo color={color}>{t(tCovers(cover.coverCode, 'name'))}</Typo>
      </Flex>

      {inQuote && onChange ? (
        <TertiaryButton layout='small' onClick={onEdit}>
          {t(tKey('actions', 'edit'))}
        </TertiaryButton>
      ) : (
        <Icon icon='info' size='tiny' />
      )}
    </FlexAnchor>
  )
}
