import React, { MouseEventHandler, useCallback, useState } from 'react'

import { TertiaryButton, TertiaryButtonProps } from './TertiaryButton'

export interface PasteButtonProps extends Omit<TertiaryButtonProps, 'onPaste'> {
  onPaste: (clipboardText: string) => void
}

const clipboardSupported = navigator?.clipboard?.readText instanceof Function

export const PasteButton = ({
  children,
  disabled,
  loading,
  onClick,
  onPaste,
  ...props
}: PasteButtonProps) => {
  const [loadingClipboard, setLoadingClipboard] = useState(false)
  const [denied, setDenied] = useState(false)

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    async (event) => {
      setLoadingClipboard(true)
      try {
        const clipboardText = await navigator.clipboard.readText()

        if (!clipboardText) return

        onPaste(clipboardText)
      } catch (error) {
        setDenied(true)
        alert(
          'Clipboard read permission was blocked.\nAllow it in site settings of your browser.',
        )
      } finally {
        setLoadingClipboard(false)
      }
      onClick?.(event)
    },
    [onClick, onPaste],
  )

  return clipboardSupported ? (
    <TertiaryButton
      data-test-id='paste-button'
      {...props}
      disabled={denied || disabled}
      loading={loadingClipboard || loading}
      onClick={handleClick}
    >
      {children}
    </TertiaryButton>
  ) : null
}
