import { Flex, LicencePlate, smBumps, Typo } from '@wrisk/ui-components'
import { default as React, FunctionComponent } from 'react'

import { toVehicleDescription } from '../../../domain'
import { tDefaults } from '../../../infrastructure/internationalisation'
import { VehicleInputValue } from '../inputs'
import { Formatter } from '../types'

export const VehicleFormatter: FunctionComponent<Formatter<VehicleInputValue>> = ({
  value,
  t,
}) =>
  value?.specification ? (
    <Flex columnGap={smBumps} justifyContent='flex-start'>
      {value?.vrn && (
        <LicencePlate licencePlate={value.vrn} size='small' variant='subtle' />
      )}
      <Typo>{toVehicleDescription(value.specification)}</Typo>
    </Flex>
  ) : (
    <>{t(tDefaults('inputs.no-answer'))}</>
  )
